import { Box, Button, Fade, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { gAPP_STORE } from '@/app/app-store';

const useStyles = makeStyles(theme => ({
  smsCodeInput: {
    '& input': {
      '-moz-appearance': 'textfield',
      '-webkit-appearance': 'none',
      appearance: 'none',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  },
}));

const LoginVerification = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [smsCode, setSmsCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [codeExpired, setCodeExpired] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(gAPP_STORE.loginStore.timeout || 300);

  useEffect(() => {
    gAPP_STORE.clearRecordsStore();

    const countdown = setInterval(() => {
      setTimer(prev => {
        if (prev === 0) {
          clearInterval(countdown);
          setCodeExpired(true);

          return 0;
        }

        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleLoginChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setSmsCode(value);
    }
  }, []);

  const handlePasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  const handleLogin = useCallback(async () => {
    const result = await gAPP_STORE.loginStore.signIn(gAPP_STORE.loginStore.login, smsCode, password);
    if (!result) {
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 1000);
    } else {
      history.push('/');
    }
  }, [smsCode, password, history]);

  const handleLoginMfa = useCallback(async () => {
    try {
      const initialTimeout = gAPP_STORE.loginStore.timeout || 300;
      setTimer(initialTimeout);

      await gAPP_STORE.loginStore.generateSMSCode(gAPP_STORE.loginStore.login);
      setCodeExpired(false);

      const countdown = setInterval(() => {
        setTimer(prev => {
          if (prev === 0) {
            clearInterval(countdown);
            setCodeExpired(true);

            return 0;
          }

          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const formatTime = useCallback((seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const formattedTime = `${minutes?.toString().padStart(2, '0')}:${secs?.toString().padStart(2, '0')}`;

    return formattedTime;
  }, []);

  return (
    <Fade in>
      <Box display="flex" flexGrow={1} flexDirection="column" alignItems="center" justifyContent="center">
        <Box p={2} width={300} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <FormControl variant="outlined" fullWidth>
            <TextField
              label={t('password')}
              variant="outlined"
              type="password"
              fullWidth
              onChange={handlePasswordChange}
            />
          </FormControl>

          <Box p={1} />
          <FormControl variant="outlined" fullWidth>
            <TextField
              className={classes.smsCodeInput}
              label={t('smsCode')}
              variant="outlined"
              type="text"
              value={smsCode}
              fullWidth
              onChange={handleLoginChange}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </FormControl>

          <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '8px' }}>
            <Grid item style={{ gap: '4px' }}>
              <Typography
                style={{
                  fontSize: '10px',
                  color: '#0000008F',
                  fontWeight: '500',
                }}
              >
                {t('resendRequest')}
              </Typography>
              <Typography
                style={{
                  fontSize: '13px',
                  color: '#000000E0',
                  fontWeight: '500',
                }}
              >
                {formatTime(timer)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                style={{
                  cursor: timer === 0 ? 'pointer' : 'not-allowed',
                  fontSize: '13px',
                  color: timer === 0 ? '#0B2B48' : '#0000003D',
                  fontWeight: '500',
                }}
                onClick={timer === 0 ? handleLoginMfa : undefined}
              >
                {t('resendCode')}
              </Typography>
            </Grid>
          </Grid>

          <Box p={1} />
          {codeExpired && (
            <Box p={1}>
              <Alert severity="warning">Истек срок действия проверочного кода. Попробуйте еще раз.</Alert>
            </Box>
          )}
          <Box p={1} />
          <Box p={1} style={{ width: '100%' }}>
            {showErrorMessage && <Alert severity="error">{t('accessDenied')}</Alert>}
          </Box>

          <Box p={1} />
          <Button onClick={handleLogin} variant="contained" color="primary" fullWidth>
            {t('next')}
          </Button>
        </Box>
        <Box p={5} />
      </Box>
    </Fade>
  );
};

export default observer(LoginVerification);
