export interface IWordDictionary {
  id: number;
  // langId: number;
  language?: string;
  name: string;
  value: string;
  createdDate: string;
  isAutoAsr: boolean;
  isKws: boolean;
  revision?: number;
  status?: number;
  kwsThreshold?: number;
}

export interface ITopicReport {
  index: number;
  name: string;
  threshold: string;
}

export class CWordDictionary implements IWordDictionary {
  id = -1;
  // langId = -1;
  name = '';
  value = '';
  createdDate = '';
  isAutoAsr = true;
  isKws = true;
  language = undefined;
}

export type TWordDictionaryTypeValue = 'auto' | 'kws' | 'both';

export interface IResponseTopic {
  recordId: number;
  name: string;
  dictionaryId: number;
  kwsThreshold: number;
  indexRevision: number;
}
