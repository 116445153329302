import i18n from 'i18next';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { EDirection } from '../languages/i-language';
import { sheldingPhrase } from '../records/docxUtils';

import { ERecordStatus, IExportSummary, IRecord } from '../records/types';

import {
  getAllMentions,
  getDictorsFromDto,
  getOwnerFromDto,
  getRoleText,
  IDictorName,
  mentionsToFullNameWithRole,
  participantsToFullNameWithRole,
  sheldFullNameWithRole,
  strToDateStr,
  strToOnlyDateStr,
} from './summaryUtils';

import {
  ETopicStatus,
  IIssue,
  IOwner,
  IParticipant,
  ISummaryItem,
  ITask,
  ITimecode,
  ITimecodesItem,
  ITopic,
} from './types';

import { gAPP_STORE } from '@/app/app-store';
import { IDictor } from '@/common/report-index';
import { UNKNOWN_SPEAKER } from '@/components/dictors/const';
import { dictorTitleWithDefaultName, getTitle } from '@/components/dictors/dictor-utils';
import { summaryService } from '@/services/summary/summary-service';
import {
  IAmbiguityDto,
  IKeyDecisionDto,
  IKeyDecisionOwnerDto,
  IKeyDecisionWithOwnerDto,
  IMentionsDto,
  IOwnerDto,
  IParticipantDto,
  ISummaryDto,
  ITaskDto,
  ITaskOwnerDto,
  ITaskWithOwnerDto,
  ITopicDto,
} from '@/services/summary/types';

type TAllowTabs = 'tab1' | 'tab2' | 'tab3';
const ALLOWTABS = ['tab1', 'tab2', 'tab3'];

export enum ETabStatus {
  notReady,
  loading,
  loaded,
  noData,
  error,
}

export enum ECollapsePanels {
  tab1Description,
  tab1Roles,
  tab1Topics,
  tab2Decision,
  tab2Issues,
}

const DEFAULT_COLLAPSE = [true, true, true, true, true];
const DEFAULT_READY_TABS: ETabStatus[] = [ETabStatus.notReady, ETabStatus.notReady, ETabStatus.notReady];
const EMPTY_TABS: ETabStatus[] = [ETabStatus.noData, ETabStatus.noData, ETabStatus.noData];

export class SummaryStore {
  private _record?: IRecord;

  private _openSummaryPanel = false;
  private _readyTabs: ETabStatus[] = DEFAULT_READY_TABS;
  private _readyTabsTranslate: ETabStatus[] = DEFAULT_READY_TABS;
  private _activeTab: TAllowTabs = 'tab1';
  private _collapsedOnTabs = DEFAULT_COLLAPSE;
  private _collapseTopics: boolean[] = [];
  private _translate = false;
  private _showHistory = false;

  private _mentionsByTopic: IMentionsDto[][] = [];
  private _participantsDto: IParticipantDto[] = [];
  private _summaryItem: ISummaryItem | undefined = undefined;
  private _summaryItemTranslate: ISummaryItem | undefined = undefined;
  private _topics: ITopic[] = [];
  private _topicsTranslate?: ITopic[] = undefined;
  private _topicTaskOwnersDto?: ITaskOwnerDto[];
  private _keyDecisionDto: IKeyDecisionDto[] = [];
  private _keyDecisionOwnersDto?: IKeyDecisionOwnerDto[] = undefined;
  private _ambiguityDto: IAmbiguityDto[] = [];
  private _keyDecisionTranslateDto?: IKeyDecisionDto[] = undefined;
  private _ambiguityTranslateDto?: IAmbiguityDto[] = undefined;

  private _rtlSummary?: EDirection;
  private _rtlSummaryTranslate?: EDirection = EDirection.LTR;

  private _historyIndex = -1;
  private _history: ISummaryDto[] = [];

  private _visibleTimecodes: ITimecode[] = [];
  private _currentTimecodeUuid?: string;
  private _selectedTimeCodeIndex = -1;

  private _returnFromGrid = false;

  setToGrid() {
    this._returnFromGrid = true;
  }

  get currentSummaryRtl() {
    const rtl = this._translate ? this._rtlSummaryTranslate : this._rtlSummary;

    return rtl ?? EDirection.LTR;
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @observable.deep private _summaryParticipants: IParticipant[] = [];

  @action
  clearTranslate() {
    this._translate = false;
    this._rtlSummaryTranslate = undefined;
    this._summaryItemTranslate = undefined;
    this._rtlSummaryTranslate = undefined;
    this._readyTabsTranslate = DEFAULT_READY_TABS;
    this._topicsTranslate = undefined;
    this._keyDecisionTranslateDto = undefined;
    this._ambiguityTranslateDto = undefined;
  }

  @action
  clearHistoryItem(jumpToTab1?: boolean) {
    this.clearTranslate();
    this._visibleTimecodes = [];
    this.selectTimeCode(-1, undefined);
    this._readyTabs = DEFAULT_READY_TABS;
    this._summaryParticipants = [];
    this._participantsDto = [];
    this._mentionsByTopic = [];
    this._topics = [];
    this._topicTaskOwnersDto = undefined;
    this._keyDecisionOwnersDto = undefined;
    this._ambiguityDto = [];
    if (jumpToTab1) {
      this._activeTab = 'tab1';
      this._collapsedOnTabs = DEFAULT_COLLAPSE;
      this._collapseTopics = [];
    }
  }

  @action
  clearHistory(nochangeTab?: boolean) {
    this._history = [];
    this._historyIndex = -1;
    this._summaryItem = undefined;
    this.clearHistoryItem(!nochangeTab);
  }

  @action
  clear() {
    if (this._returnFromGrid) return;
    this._record = undefined;
    this._rtlSummary = undefined;
    this.clearHistory();
  }

  @action
  updateDictors() {
    const dictorStore = gAPP_STORE.getDictorsStore();
    const recordDictors =
      this._record && this._record.correlationId
        ? dictorStore.getDictorsWithSegmentationForRecord(this._record.correlationId)
        : [];

    this._summaryParticipants = this._participantsDto ? getDictorsFromDto(this._participantsDto, recordDictors) : [];
    this._topics.forEach(topic => {
      if (topic.participantDto) {
        topic.participants = getDictorsFromDto(topic.participantDto, recordDictors);
      }
    });
  }

  updateDictorsInfo() {
    this.updateDictors();
    this.loadOpenedTopics();
  }

  @action
  restore(store: SummaryStore) {
    const copyFieldValue = <T, K extends keyof T>(dest: T, source: T, key: K) => {
      dest[key] = source[key];
    };
    for (const key of Object.keys(store) as (keyof SummaryStore)[]) {
      copyFieldValue(this, store, key);
    }

    //!!! - вызов после прописывания дикторов!!!
    this.updateDictors();
  }

  @computed
  public get returnFromGrid() {
    return this._returnFromGrid;
  }

  //------------------------------------------------------------------------
  @computed
  public currentHistory() {
    return this._historyIndex;
  }

  @computed
  public get history() {
    return this._history;
  }

  @action
  public changeHistory(historyIndex: number) {
    this.clearHistoryItem(true);
    runInAction(() => {
      this.setStatusTab(0, ETabStatus.loading);
      this._historyIndex = historyIndex;
      this._dtoToSummary(this._history[historyIndex]);
    });

    try {
      this._loadMainTabForHistoryItem(this._history[historyIndex].id);
      this.setStatusTab(0, ETabStatus.loaded);
    } catch {
      this.setStatusTab(0, ETabStatus.error);
    }
  }

  @computed
  public get visibleTimecodes() {
    return this._visibleTimecodes;
  }

  @computed
  public get currentTimecodeUuid() {
    return this._currentTimecodeUuid;
  }

  @computed
  public get selectedTimeCodeIndex() {
    return this._selectedTimeCodeIndex;
  }

  @computed isCounterActive(uuid?: string) {
    return !!uuid && uuid === this._currentTimecodeUuid && this._selectedTimeCodeIndex === -1;
  }

  @action
  public selectTimeCode(index: number, uuid?: string) {
    if (uuid && uuid === this._currentTimecodeUuid) {
      this._selectedTimeCodeIndex = index;
    } else {
      this._currentTimecodeUuid = uuid;
      this._selectedTimeCodeIndex = index;
    }
  }

  @action
  public setVisibleTimecodes(value?: ITimecodesItem) {
    this._visibleTimecodes = value ? [...value.timecodes] : [];
    this._currentTimecodeUuid = value?.uuid;
    this._selectedTimeCodeIndex = -1;
  }

  @computed
  public isShowSummrySegmentation() {
    return this.visibleTimecodes.length === 0;
  }

  @computed
  public isShow(indPanel: number) {
    return this._collapsedOnTabs[indPanel];
  }

  @action
  public switchShow(indPanel: number) {
    this._collapsedOnTabs[indPanel] = !this._collapsedOnTabs[indPanel];
  }

  @computed
  public isTopicShow(index: number) {
    return this._collapseTopics[index];
  }

  @action
  public switchTopicsShow(index: number) {
    const isTopicOpen = this._collapseTopics[index];
    if (this._translate && !this._topicsTranslate) {
      console.error(`Translate of topics aren't loaded (index=${index}) `);

      return;
    }
    const topic = this._translate && this._topicsTranslate ? this._topicsTranslate[index] : this._topics[index];

    if (!isTopicOpen && (!topic?.status || topic?.status === ETopicStatus.notReady)) {
      topic.status = ETopicStatus.loading; //!!
      void this.loadTopic(index, this._translate);
    }

    this._collapseTopics[index] = !this._collapseTopics[index];
  }

  loadTopicFromBack(id: number) {
    const topic = this.topics[id];

    if (topic.status === ETopicStatus.notReady) {
      topic.status = ETopicStatus.loading; //!!
      void this.loadTopic(id, this._translate);
    }
  }

  checkAndLoadTopics() {
    this._collapseTopics.forEach((_, index) => {
      if (this._collapseTopics[index]) {
        this.loadTopicFromBack(index);
      }
    });
  }

  //------------------------------------------------------------------------
  @computed getSpeakerById(speakerId: number | null | undefined) {
    return speakerId ? `${speakerId}` : '';
  }

  @computed
  getDecisionOwner(decisionId: number, names?: IDictorName[]): IOwner {
    return getOwnerFromDto(
      this.summaryParticipants,
      this._keyDecisionOwnersDto?.filter(owner => owner.keyDecisionId === decisionId),
      getTitle,
      names,
    );

    // const result: IOwner = {
    //   idOwner: 0,
    //   name: '',
    //   editable: true,
    // };

    // const owners = this._keyDecisionOwnersDto?.filter(owner => owner.keyDecisionId === decisionId);
    // const owner = owners && owners.length > 0 ? owners[0] : undefined;
    // if (owner) {
    //   result.idOwner = owner.id;
    //   result.name = owner.name;
    //   if (owner.speakerId) {
    //     const participant = this.summaryParticipants.find(p => p.idParticipant === owner.speakerId);
    //     if (participant && participant.dictor) {
    //       result.editable = false;
    //       result.name = getTitle(participant.dictor);
    //     }
    //   }
    // }

    // return result;
  }

  private _getDecisionsFromDto(keyDecisionDto?: IKeyDecisionDto[]) {
    if (!keyDecisionDto) {
      return [];
    }
    const ownerParticipants = new Map<number, { owner: IOwnerDto; participant: IParticipant | undefined }>();
    const ownersByDecision = new Map<number, number>();
    (this._keyDecisionOwnersDto ?? []).forEach(item => {
      if (!ownerParticipants.has(item.id)) {
        const participant = this.summaryParticipants.find(p => p.idParticipant === item.id);
        ownerParticipants.set(item.id, { owner: item, participant });
      }

      if (!ownersByDecision.has(item.keyDecisionId)) {
        ownersByDecision.set(item.keyDecisionId, item.id);
      }
    });

    return keyDecisionDto?.map(item => {
      return {
        id: item.id || 0,
        title: item.title ?? '',
        description: item.description ?? '',
        deadline: {
          deadline: item.deadline ? strToOnlyDateStr(item.deadline) : '',
          estimation: item.estimation ?? '',
        },
        timecodesItem: item.phraseLinks
          ? { uuid: item.uuid ?? '', timecodes: this.pharaseLinksToTimecodes(item.phraseLinks) }
          : undefined,
      } as unknown as IIssue;
    });
  }

  @computed
  get decisions() {
    return this._translate
      ? this._getDecisionsFromDto(this._keyDecisionTranslateDto)
      : this._getDecisionsFromDto(this._keyDecisionDto);
  }

  private _getAmbiguitiesFromDto(dto?: IAmbiguityDto[]) {
    return dto
      ? dto.map(
          item =>
            ({
              id: item.id,
              title: item.title ?? '',
              description: item.description ?? '',
              timecodesItem: item.phraseLinks
                ? { uuid: item.uuid ?? '', timecodes: this.pharaseLinksToTimecodes(item.phraseLinks) }
                : undefined,
            } as unknown as IIssue),
        )
      : [];
  }

  @computed
  get ambiguities() {
    return this._translate
      ? this._getAmbiguitiesFromDto(this._ambiguityTranslateDto)
      : this._getAmbiguitiesFromDto(this._ambiguityDto);
  }

  //------------------------------------------------------------------------
  get recordId() {
    return this._record?.id;
  }

  get isTranslate() {
    return this._translate;
  }

  private _historyStatusBeforeTranslateMode = false;
  @action setTranslateMode(value: boolean) {
    if (this._translate !== value) {
      this._translate = value;
      if (value) {
        this._historyStatusBeforeTranslateMode = this._showHistory;
        this.setShowHistory(false);
        void this.loadTranslate();
        this._collapseTopics.forEach((_, index) => {
          if (this._collapseTopics[index]) {
            void this.loadTopic(index, value, true);
          }
        });
      } else {
        if (this._historyStatusBeforeTranslateMode) {
          this.setShowHistory(true);
        }
        void this._loadSource();
        this._collapseTopics.forEach((_, index) => {
          if (this._collapseTopics[index]) {
            void this.loadTopic(index, value, true);
          }
        });
      }
    }
  }

  get showHistory() {
    return this._showHistory;
  }

  @action setShowHistory(value: boolean) {
    this._showHistory = value;
  }

  get activeTab() {
    return this._activeTab;
  }

  @action
  setActiveTab(value: TAllowTabs) {
    if (value !== this._activeTab) {
      this._visibleTimecodes = [];
      this.selectTimeCode(-1, undefined);
    }
    if (this.summaryHistoryReady) {
      this._activeTab = value;
      if (this.isTranslate) {
        this.loadTranslate();
      } else {
        switch (value) {
          case 'tab1':
            break;
          case 'tab2':
            this.loadSecondTab();
            break;
          case 'tab3':
            this.checkAndLoadTopics();
            break;
          default:
            break;
        }
      }
    }
  }

  private async _loadSource() {
    const tabIndex = this.getTabIndex();
    if (!this._summaryItem || this._readyTabs[tabIndex] === ETabStatus.loaded) return;
    this.setStatusTab(tabIndex, ETabStatus.loading);
    try {
      if (tabIndex === 1) {
        await this.loadSecondTabFromBack(this._summaryItem.summarydId, false);
      }
      this.setStatusTab(tabIndex, this._isTabEmpty(tabIndex) ? ETabStatus.noData : ETabStatus.loaded);
      if (tabIndex === 2) {
        this.checkAndLoadTopics();
      }
    } catch {
      this.setStatusTab(tabIndex, ETabStatus.error);
    }
  }

  @computed isActiveTab(value: TAllowTabs) {
    return this._activeTab === value;
  }

  get openSummaryPanel() {
    return this._openSummaryPanel;
  }

  @action setSummaryPanel(value: boolean) {
    if (!value && this._returnFromGrid) return;
    if (value) {
      this._returnFromGrid = false;
    }

    this._openSummaryPanel = value;
    if (!value) {
      this.clear();
    }
  }

  @computed get summaryStatus() {
    return this._record?.summaryStatus;
  }

  @computed get summaryTranslationStatus() {
    return this._record?.summaryTranslationStatus;
  }

  @computed summaryTitle() {
    if (!this._record || this._record.summaryStatus !== ERecordStatus.PROCESSED) {
      return '';
    }
    if (this._readyTabs[0] !== ETabStatus.loaded) {
      return i18n.t('summary.summaryLoading');
    }

    const sum = this.summary;
    if (!sum || (!sum.title && !sum.date)) {
      return '';
    }

    return this.currentSummaryRtl === EDirection.LTR
      ? `${strToDateStr(sum.date)}: ${sum.title}`
      : `${sum.title}: ${strToDateStr(sum.date)}`;
  }

  get description() {
    return (this._translate ? this._summaryItemTranslate?.description : this._summaryItem?.description) ?? '';
  }

  get summary() {
    return this._translate ? this._summaryItemTranslate : this._summaryItem;
  }

  get summaryParticipants() {
    return this._summaryParticipants ?? [];
  }

  get topics(): ITopic[] {
    return (this._translate ? this._topicsTranslate : this._topics) ?? [];
  }

  get allTopicsReady(): boolean {
    return this.topics.every(topic => topic.status === ETopicStatus.ready);
  }

  getTopicByInd(topicIndex: number) {
    return this._topics ? this._topics[topicIndex] : [];
  }

  get topicCount() {
    return this._topics?.length ?? 0;
  }

  @computed
  get summaryMentions() {
    return getAllMentions(this._mentionsByTopic).join(', ');
  }

  @computed getTopicMentions(topicInd: number) {
    return this._mentionsByTopic[topicInd].map(item => getRoleText(item)).join(', ');
  }

  //------------------------------------------------------------------------

  private showError() {
    toast.error(i18n.t('summary.errorLoad'), { style: { fontSize: '1.2rem' } });
  }

  private showUpdateError() {
    toast.error(i18n.t('summary.errorUpdate'), { style: { fontSize: '1.2rem' } });
  }

  private showResummarizeError() {
    toast.error(i18n.t('summary.errorResummarize'), { style: { fontSize: '1.2rem' } });
  }

  private showRetranslateError() {
    toast.error(i18n.t('summary.errorRetranslate'), { style: { fontSize: '1.2rem' } });
  }

  //------------------------------------------------------------------------

  @computed
  getTaskOwner(taskId: number, names?: IDictorName[]): IOwner {
    return getOwnerFromDto(
      this.summaryParticipants,
      this._topicTaskOwnersDto?.filter(owner => owner.taskId === taskId),
      getTitle,
      names,
    );
  }

  private getTasksFromDto(tasksDto: ITaskDto[]) {
    const tasks: ITask[] = [];
    if (tasksDto?.length === 0) {
      return tasks;
    }

    tasksDto.forEach(task => {
      tasks.push({
        idTask: task.id,
        issue: task.issue ?? '',
        decision: task.decision ?? '',
        deadline: task.deadline ? strToOnlyDateStr(task.deadline) : '',
        estimation: task.estimation ?? '',
      });
    });

    return tasks;
  }

  private getTopicsFromDto(topicsDto: ITopicDto[]): ITopic[] {
    return topicsDto.map(item => ({
      idTopic: item.id,
      title: item.title ?? '',
      description: item.description ?? '',
      participants: [],
      timecodesItem: item.phraseLinks
        ? { uuid: item.uuid ?? '', timecodes: this.pharaseLinksToTimecodes(item.phraseLinks) }
        : undefined,
      status: ETopicStatus.notReady, //!!
    }));
  }

  private _dtoToSummary(summaryDto: ISummaryDto) {
    this._summaryItem = {
      summarydId: summaryDto.id,
      title: summaryDto.title ?? '',
      date: summaryDto.date ?? '',
      description: summaryDto.description ?? '',
    };
  }

  private pharaseLinksToTimecodes(phraseLinks: string): ITimecode[] {
    const deltas: ITimecode[] = JSON.parse(phraseLinks);

    const result: ITimecode[] = [];
    let t1 = deltas[0].begin;
    let t2 = t1;
    const D = gAPP_STORE.summarizationPauseLength;
    deltas.forEach((a: ITimecode) => {
      if (a.begin - t2 > D) {
        result.push({ begin: t1, end: t2 });
        t1 = a.begin;
      }
      t2 = a.end;
    });
    result.push({ begin: t1, end: t2 });

    result.sort((a, b) => a.begin - b.begin);

    return result;
  }

  private async _loadMainTabForHistoryItem(currentSummaryId: number) {
    const isTranslate = this.isTranslate;
    const dictorsDto = await summaryService.getSummaryRecipinets(currentSummaryId);
    const topicsDto = await summaryService.getTopics(currentSummaryId, isTranslate);

    if (topicsDto.length > 0) {
      const job: Promise<IMentionsDto[] | undefined>[] = topicsDto.map(topic =>
        summaryService.getTopicMentions(topic.id),
      );
      const jobResults = await Promise.allSettled(job);
      const noResults = jobResults.filter(res => res.status === 'rejected');
      if (noResults.length > 0) {
        throw new Error('SummaryService');
      }
      jobResults.forEach(res => {
        if (res.status !== 'rejected') {
          this._mentionsByTopic.push(res.value === undefined ? [] : (res.value as IMentionsDto[]));
        }
      });

      const mentionsSet = new Set<string>();
      this._mentionsByTopic.forEach(lst =>
        lst.forEach(item => {
          mentionsSet.add(
            item.role === null || item.role === undefined || item.role.trim() === ''
              ? item.name
              : `${item.name}(${item.role.trim()})`,
          );
        }),
      );
    }

    const dictorStore = gAPP_STORE.getDictorsStore();
    const recordDictors =
      this._record && this._record.correlationId
        ? dictorStore.getDictorsWithSegmentationForRecord(this._record.correlationId)
        : [];

    runInAction(() => {
      this._participantsDto = dictorsDto;
      this._summaryParticipants = getDictorsFromDto(dictorsDto, recordDictors);
      this._topics = this.getTopicsFromDto(topicsDto);
    });
  }

  private async _loadMainTabFromBack(recordId: number) {
    try {
      const summaryDtoRevisions = await summaryService.getBrief(recordId);
      const lstRevision = summaryDtoRevisions.length - 1;
      if (lstRevision < 0) return;

      await this._loadMainTabForHistoryItem(summaryDtoRevisions[lstRevision].id);

      runInAction(() => {
        this._history = summaryDtoRevisions;
        this._historyIndex = lstRevision;
        this._dtoToSummary(summaryDtoRevisions[lstRevision]);
      });
    } catch {
      this.showError();
    }
  }

  async loadOpenedTopics(force?: boolean) {
    const topicRequests: Promise<void>[] = [];

    this._collapseTopics.forEach((topicOpened, index) => {
      if (topicOpened) {
        this._topics[index].status = ETopicStatus.loading;

        topicRequests.push(this.loadTopic(index, this._translate, true));
      }
    });

    await Promise.all(topicRequests);
  }

  private async _getSummaryTranslate(summaryId: number): Promise<boolean> {
    const summaryTranslate = await summaryService.getTranslate(summaryId);
    if (!summaryTranslate) {
      this._readyTabsTranslate = EMPTY_TABS;

      return false;
    }
    if (!summaryTranslate.translationLanguage) {
      console.log(" The Language of translate's summaty is undefined.");
    }
    runInAction(() => {
      this._rtlSummaryTranslate = !summaryTranslate.translationLanguage
        ? this._rtlSummary
        : gAPP_STORE.isRtlLanguage(summaryTranslate.translationLanguage)
        ? EDirection.RTL
        : EDirection.LTR;
      this._summaryItemTranslate = {
        summarydId: this._summaryItem ? this._summaryItem.summarydId : -1,
        title: summaryTranslate.title ?? '',
        date: summaryTranslate.date ?? '',
        description: summaryTranslate.description ?? '',
      };
    });

    return true;
  }

  private getTabIndex = () => (ALLOWTABS.indexOf(this._activeTab) > 0 ? ALLOWTABS.indexOf(this._activeTab) : 0);

  private _isTabEmpty(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return this._translate
          ? this.description === ' ' && !!!this._topicsTranslate
          : this.description === ' ' && !!!this._topics && !!!this._participantsDto && !!!this.summaryMentions.trim();
      case 1:
        return this._translate
          ? !!!this._ambiguityTranslateDto && !!!this._keyDecisionTranslateDto
          : !!!this._ambiguityDto?.length && !!!this._keyDecisionDto;
      case 2:
        return this._translate ? !!!this._topicsTranslate : !!!this._topics;
      default:
        return true;
    }
  }

  @action
  setTabTranslateEmpty() {
    this._readyTabsTranslate = EMPTY_TABS;
  }

  @action
  setTabTranslateDefault() {
    this._readyTabsTranslate = DEFAULT_READY_TABS;
  }

  async loadTranslate() {
    if (!this._summaryItem) {
      console.error('call translate for undefined summary');

      return;
    }
    const tabIndex = this.getTabIndex();
    if (this._readyTabsTranslate[tabIndex] === ETabStatus.loaded) return;

    runInAction(() => {
      this._readyTabsTranslate[tabIndex] = ETabStatus.loading;
    });
    try {
      if (!this._rtlSummaryTranslate) {
        const good = await this._getSummaryTranslate(this._summaryItem.summarydId);
        if (!good) {
          return;
        }
      }

      switch (tabIndex) {
        case 1:
          await this.loadSecondTabFromBack(this._summaryItem.summarydId, true);
          break;
        case 2:
        case 0:
          if (!this._topicsTranslate) {
            const topicDto = await summaryService.getTopics(this._summaryItem.summarydId, true);
            this._topicsTranslate = this.getTopicsFromDto(topicDto);
          }
          break;
        default:
          console.error(`unknown tab: ${this._activeTab}`);
      }
      this.setStatusTab(tabIndex, this._isTabEmpty(tabIndex) ? ETabStatus.noData : ETabStatus.loaded);
      if (tabIndex === 2 && this._readyTabsTranslate[2] === ETabStatus.loaded) {
        this.checkAndLoadTopics();
      }
    } catch (error) {
      console.log('Error while switch Summary to Translation: ', { error, tab: this._activeTab });
      runInAction(() => {
        this._readyTabsTranslate[tabIndex] = ETabStatus.error;
      });
    }
  }

  async loadMainTab(record: IRecord, nochangeTab?: boolean) {
    if (this._returnFromGrid) {
      this._returnFromGrid = false;

      return;
    }

    if (record.id !== this._record?.id) {
      this.clear();
      runInAction(() => {
        this._record = record;
      });
    } else {
      this.clearHistory(nochangeTab);
    }

    if (record.summaryStatus !== ERecordStatus.PROCESSED) {
      return;
    }
    this.setStatusTab(0, ETabStatus.loading);

    const recordLanguage = record.languageResolved;
    const isRTL = gAPP_STORE.isRtlLanguage(recordLanguage);
    runInAction(() => {
      this._rtlSummary = isRTL ? EDirection.RTL : EDirection.LTR;
    });

    try {
      await this._loadMainTabFromBack(record.id);

      this.setStatusTab(0, ETabStatus.loaded);
      this.setStatusTab(2, this._isTabEmpty(2) ? ETabStatus.noData : ETabStatus.loaded);
    } catch {
      this.setStatusTab(0, ETabStatus.error);
    }
  }

  updateOwnerName(name: string, index: number) {
    return name.includes(UNKNOWN_SPEAKER) ? `${i18n.t(UNKNOWN_SPEAKER)} ${index}` : name;
  }

  private async loadSecondTabFromBack(summaryId: number, isTranslate: boolean) {
    const jobs: Promise<unknown>[] = [
      summaryService.getSummaryKeyDecisions(summaryId, isTranslate),
      summaryService.getSummaryAmbiguities(summaryId, isTranslate),
    ];
    if (this._keyDecisionOwnersDto === undefined) {
      jobs.push(summaryService.getKeyDecisionOwners(summaryId));
    }
    const results = await Promise.allSettled(jobs);

    if (results[0].status === 'rejected' && results[1].status === 'rejected') {
      throw new Error(`Summary has no KeyDecisions, no Ambiguities\n:${results[0].reason}\n${results[1].reason}`);
    }

    runInAction(() => {
      if (this._keyDecisionOwnersDto === undefined) {
        this._keyDecisionOwnersDto =
          results[2].status === 'fulfilled' && results[2].value && Array.isArray(results[2].value)
            ? results[2].value.map((owner, i) => ({ ...owner, name: this.updateOwnerName(owner.name, i) }))
            : [];
      }
      if (isTranslate) {
        this._keyDecisionTranslateDto =
          results[0].status === 'fulfilled' && results[0].value && Array.isArray(results[0].value)
            ? results[0].value
            : [];
        this._ambiguityTranslateDto =
          results[1].status === 'fulfilled' && results[1].value && Array.isArray(results[1].value)
            ? results[1].value
            : [];
      } else {
        this._keyDecisionDto =
          results[0].status === 'fulfilled' && results[0].value && Array.isArray(results[0].value)
            ? results[0].value
            : [];
        this._ambiguityDto =
          results[1].status === 'fulfilled' && results[1].value && Array.isArray(results[1].value)
            ? results[1].value
            : [];
      }
    });
  }

  get summaryHistoryReady() {
    return this._readyTabs[0] === ETabStatus.loaded;
  }

  get firstTabStatus() {
    return this._translate ? this._readyTabsTranslate[0] : this._readyTabs[0];
  }

  get secondTabStatus() {
    return this._translate ? this._readyTabsTranslate[1] : this._readyTabs[1];
  }

  get thirdTabStatus() {
    return this._translate ? this._readyTabsTranslate[2] : this._readyTabs[2];
  }

  get loadedTabs() {
    const { firstTabStatus, secondTabStatus, thirdTabStatus } = this;

    return [firstTabStatus, secondTabStatus, thirdTabStatus].filter(value => value === ETabStatus.loaded);
  }

  @action
  setStatusTab(tabIndex: number, value: ETabStatus) {
    if (this._translate) {
      this._readyTabsTranslate[tabIndex] = value;
    } else {
      this._readyTabs[tabIndex] = value;
    }
  }

  hasDecisionsPanelData() {
    return this._keyDecisionDto.length > 0 || this._ambiguityDto.length > 0;
  }

  async loadSecondTab(force?: boolean) {
    if (!this._summaryItem) {
      this.setStatusTab(1, ETabStatus.error);

      return;
    }

    if (this._readyTabs[1] === ETabStatus.loaded && !force) {
      return;
    }

    if (force) {
      this._keyDecisionOwnersDto = undefined;
    }

    this.setStatusTab(1, ETabStatus.loading);

    try {
      await this.loadSecondTabFromBack(this._summaryItem.summarydId, this._translate);
      this.setStatusTab(
        1,
        this._ambiguityDto.length > 0 || this._keyDecisionDto.length > 0 ? ETabStatus.loaded : ETabStatus.noData,
      );
    } catch {
      this.setStatusTab(1, ETabStatus.error);
    }
  }

  async loadTopicOwners() {
    if (!this._summaryItem || this._topicTaskOwnersDto !== undefined) {
      return;
    }
    const ownersDto = await summaryService.getTaskOwners(this._summaryItem.summarydId);
    runInAction(() => {
      this._topicTaskOwnersDto = ownersDto;
    });
  }

  async loadTopic(index: number, isTranslate: boolean, force?: boolean) {
    const curTopic = this.topics[index];
    console.log(curTopic, index);

    let dictorsDto = curTopic?.participantDto;

    if (!dictorsDto) {
      await this.loadTopicOwners();
      dictorsDto = await summaryService.getTopicParticipants(curTopic?.idTopic);
    }

    const hasTasks = curTopic?.tasks;
    console.log(hasTasks);

    let tasks: ITaskDto[] = [];

    if (!hasTasks || force) {
      tasks = await summaryService.getTasks(curTopic?.idTopic, isTranslate);
    }

    runInAction(() => {
      if (isTranslate) {
        curTopic.tasks = this.getTasksFromDto(tasks);
      } else {
        curTopic.tasks = this.getTasksFromDto(tasks);
      }

      if (!curTopic.participantDto && dictorsDto) {
        const dictorStore = gAPP_STORE.getDictorsStore();
        const recordDictors =
          this._record && this._record.correlationId
            ? dictorStore.getDictorsWithSegmentationForRecord(this._record.correlationId)
            : [];

        curTopic.participants = getDictorsFromDto(dictorsDto, recordDictors);
        curTopic.participantDto = dictorsDto;
      }

      curTopic.status = ETopicStatus.ready; //!!
    });
  }

  async updateDescription(value: string) {
    if (this.isTranslate) {
      return console.error('The translation of the summary is not editable');
    }

    if (this._record && this._summaryItem && value !== this.summary?.description) {
      const summaryDto: ISummaryDto = {
        id: this._summaryItem.summarydId,
        recordId: this._record.id,
        date: this._summaryItem.date,
        title: this._summaryItem.title,
        description: value,
        revision: 0, //?
      };
      try {
        await summaryService.updateBrief(this._summaryItem.summarydId, summaryDto);
        await this.loadMainTab(this._record, true);
      } catch {
        this.showUpdateError();
      }
    }
  }

  async updateRole(participant: IParticipant, value: string) {
    if (this.isTranslate) {
      console.error('The translation of the summary is not editable');

      return;
    }
    if (this._record && this._summaryItem && participant?.role !== value) {
      const participantDto: IParticipantDto = {
        id: participant.idParticipant,
        dictorId: participant.dictor?.id ?? null,
        summaryId: this._summaryItem.summarydId,
        name: participant.summaryName,
        role: value,
      };

      try {
        await summaryService.updateTopicParticipant(this._summaryItem.summarydId, participantDto);
        await this.loadMainTab(this._record, true);
      } catch {
        this.showUpdateError();
      }
    }
  }

  async updateTopic(topic: ITopic, field: keyof ITopic, value: string) {
    if (this.isTranslate) {
      console.error('The translation of the summary is not editable');

      return;
    }

    if (this._record && this._summaryItem) {
      const topicDto: ITopicDto = {
        id: topic.idTopic,
        summaryId: this._summaryItem.summarydId,
        title: field === 'title' ? value : topic.title,
        description: field === 'description' ? value : topic.description,
        phraseLinks: topic.phraseLinks,
      };
      try {
        await summaryService.updateTopic(this._summaryItem.summarydId, topicDto);
        await this.loadMainTab(this._record, true);
      } catch {
        this.showUpdateError();
      }
    }
  }

  async updateTask(task: ITask, fields: string[], values: (string | number)[], topic: ITopic) {
    if (this.isTranslate) {
      return console.error('The translation of the summary is not editable');
    }

    const indRO = fields.indexOf('ownerRO');
    const indOwnerId = fields.indexOf('ownerId');
    const indOwnerName = fields.indexOf('ownerName');
    let ownerChanged = indRO >= 0 && values[indRO] === 'false' && indOwnerId >= 0 && indOwnerName >= 0;
    if (ownerChanged) {
      if (indOwnerId > 0) {
        const owner = this._topicTaskOwnersDto?.find(owner => owner.id === values[indOwnerId]);
        ownerChanged = !!owner && owner.name.trim() !== `${values[indOwnerName]}`.trim();
      } else {
        ownerChanged = `${values[indOwnerName]}`.trim() !== '';
      }
    }

    let taskChanged = false;

    const taskDto: ITaskDto = {
      id: task.idTask,
      topicId: topic.idTopic,
      decision: task.decision,
      issue: task.issue,
      deadline: task.deadline || null,
      estimation: task.estimation || null,
    };

    fields.forEach((field, index) => {
      switch (field) {
        case 'decision':
        case 'issue':
        case 'estimation':
          if (task[field] !== values[index]) {
            taskDto[field] = `${values[index]}`;
            taskChanged = true;
          }
          break;
        case 'deadline':
          if (task[field]?.split('T')[0] !== values[index]) {
            taskDto.deadline = values[index] ? `${values[index]}T00:00:00` : '';
            taskChanged = true;
          }
          break;
      }
    });

    if (this._record && this._summaryItem && (ownerChanged || taskChanged)) {
      try {
        if (ownerChanged) {
          const onwnerId = +values[indOwnerId];
          const taskWithOwnerDto: ITaskWithOwnerDto = {
            ...taskDto,
            ownerId: onwnerId === 0 ? null : onwnerId,
            ownerName: `${values[indOwnerName]}`.trim(),
          };
          await summaryService.updateTaskWithOwner(this._summaryItem.summarydId, taskWithOwnerDto);
        } else {
          await summaryService.updateTask(this._summaryItem.summarydId, taskDto);
        }

        await this.loadMainTab(this._record, true);
        await this.loadTopicOwners();
        await this.loadOpenedTopics(true);
      } catch {
        this.showUpdateError();
      }
    }
  }

  async updateDecision(decision: IIssue, fields: string[], values: (string | number)[]) {
    if (this.isTranslate) {
      return console.error('The translation of the summary is not editable');
    }

    const indRO = fields.indexOf('ownerRO');
    const indOwnerId = fields.indexOf('ownerId');
    const indOwnerName = fields.indexOf('ownerName');
    let ownerChanged = indRO >= 0 && values[indRO] === 'false' && indOwnerId >= 0 && indOwnerName >= 0;
    if (ownerChanged) {
      if (indOwnerId > 0) {
        const owner = this._keyDecisionOwnersDto?.find(owner => owner.id === values[indOwnerId]);
        ownerChanged = !!owner && owner.name.trim() !== `${values[indOwnerName]}`.trim();
      } else {
        ownerChanged = `${values[indOwnerName]}`.trim() !== '';
      }
    }

    let decisionChanged = false;
    const decisionDto: IKeyDecisionDto | undefined = this._keyDecisionDto.find(dto => dto.id === decision.id);

    fields.forEach((field, index) => {
      switch (field) {
        case 'title':
        case 'description':
          if (decisionDto && decision[field] !== values[index]) {
            decisionDto[field] = `${values[index]}`;
            decisionChanged = true;
          }
          break;
        case 'deadline':
          if (decisionDto && decision.deadline?.[field]?.split('T')[0] !== values[index]) {
            decisionDto.deadline = values[index] ? `${values[index]}T00:00:00` : '';
            decisionChanged = true;
          }
          break;
        case 'estimation':
          if (decisionDto && decision.deadline?.[field] !== values[index]) {
            decisionDto.estimation = `${values[index]}`;
            decisionChanged = true;
          }
          break;
      }
    });

    if (this._record && this._summaryItem && (ownerChanged || decisionChanged)) {
      try {
        if (ownerChanged && decisionDto) {
          const onwnerId = +values[indOwnerId];
          const decisionWithOwnerDto: IKeyDecisionWithOwnerDto = {
            ...decisionDto,
            ownerId: onwnerId === 0 ? null : onwnerId,
            ownerName: `${values[indOwnerName]}`.trim(),
          };
          await summaryService.updateDecisionWithOwner(this._summaryItem.summarydId, decisionWithOwnerDto);
        } else {
          await summaryService.updateKeyDecision(this._summaryItem.summarydId, decisionDto as IKeyDecisionDto);
        }

        await this.loadMainTab(this._record, true);
        await this.loadSecondTab(true);
      } catch {
        this.showUpdateError();
      }
    }
  }

  async updateAmbiguity(item: IIssue, field: string, value: string) {
    if (this.isTranslate) {
      console.error('The translation of the summary is not editable');

      return;
    }

    const currentAmbiguityDTO = this._ambiguityDto.find(dto => dto.id === item.id);

    if (this._record && this._summaryItem && currentAmbiguityDTO) {
      const ambuguityDto: IAmbiguityDto = {
        ...currentAmbiguityDTO,
        title: field === 'title' ? value : item.title,
        description: field === 'description' ? value : item.description,
      };

      try {
        await summaryService.updateAmbiguity(this._summaryItem.summarydId, ambuguityDto);
        await this.loadMainTab(this._record, true);
        await this.loadSecondTab(true);
      } catch {
        this.showUpdateError();
      }
    }
  }

  private _participantToString(participant: IParticipant) {
    const name = participant.dictor ? getTitle(participant.dictor) : participant.summaryName;
    const role = !!participant.role ? `(${participant.role})` : '';

    return `${name}${role}`;
  }

  private _getSpeakersName(dictors: IDictor[], defaultDictor: string): IDictorName[] {
    const speakers = [...dictors];
    speakers.sort((a, b) => a.id - b.id);

    return speakers.map((speaker, index) => {
      const title = dictorTitleWithDefaultName(speaker, index, defaultDictor);
      const name = title
        ? speaker.surname && !title.includes(speaker.surname)
          ? `${title} ${speaker.surname}`
          : title
        : '';

      return { id: speaker.id, name };
    });
  }

  async prepareForReport(
    record: IRecord,
    dictors: IDictor[],
    defaultDictor: string,
    isRTL: boolean,
    isTranslateRtl: boolean,
  ): Promise<IExportSummary> {
    if (record.summaryStatus !== ERecordStatus.PROCESSED) {
      return {
        title: '',
        date: '',
        description: '',
        summarySpeakers: '',
        summaryMentions: '',
        keyDecisions: [],
        ambiguities: [],
        summaryTopics: [],
      };
    }

    if (!(this._record && record.id === this._record.id && this._readyTabs[0] === ETabStatus.loaded)) {
      await this.loadMainTab(record, true);
    }

    await this.loadSecondTab();

    const topicPromise = this._topics.map((topic, ind) =>
      topic.status === 'Ready' ? null : this.loadTopic(ind, false),
    );
    await Promise.allSettled(topicPromise);
    const names = this._getSpeakersName(dictors, defaultDictor);
    const sheld = isRTL ? (s: string) => sheldingPhrase([s]) : (s: string) => s;
    const sheldArray = isRTL ? (ss: string[]) => sheldingPhrase(ss) : (ss: string[]) => ss.join(', ');

    return {
      title: this._summaryItem ? sheld(this._summaryItem.title) : '',
      date: this._summaryItem && this._summaryItem.date ? strToOnlyDateStr(this._summaryItem.date) : '',
      description: this._summaryItem ? sheld(this._summaryItem.description) : '',
      summarySpeakers: sheldFullNameWithRole(participantsToFullNameWithRole(this.summaryParticipants, names), isRTL),
      summaryMentions: sheldFullNameWithRole(mentionsToFullNameWithRole(-1, this._mentionsByTopic, names), isRTL),
      keyDecisions: this._keyDecisionDto.map(issue => ({
        KeyDecisionsTitle: issue.title ? sheld(issue.title) : '',
        KeyDecisionsDescription: sheld(issue.description ?? ''),
        KeyDecisionsOwner: sheld(this.getDecisionOwner(issue.id, names).name ?? ''),
        KeyDecisionsDeadline: issue.deadline ? strToOnlyDateStr(issue.deadline) : '',
        KeyDecisionsEstimation: issue.estimation ? sheld(issue.estimation) : '',
      })),
      ambiguities: this._ambiguityDto.map(issue => ({
        summaryAmbiguitiesTitle: sheld(issue.title ?? ''),
        summaryAmbiguitiesDescription: sheld(issue.description ?? ''),
      })),
      summaryTopics: this.topics.map((topic, topicIndex) => {
        const topicsTasks = (topic.tasks ?? []).map(task => ({
          tasksIssue: task.issue ? sheld(task.issue) : '',
          tasksDecision: sheld(task.decision ?? ''),
          tasksOwner: sheld(this.getTaskOwner(task.idTask, names).name ?? ''),
          tasksDeadline: task.deadline ? strToOnlyDateStr(task.deadline) : '',
          tasksEstimation: sheld(task.estimation ?? ''),
        }));

        return {
          topicsTitle: sheld(topic.title ?? ''),
          topicsDescription: sheld(topic.description ?? ''),
          topicsSpeakers: sheldFullNameWithRole(participantsToFullNameWithRole(topic.participants, names), isRTL),
          topicsMentions: sheldFullNameWithRole(
            mentionsToFullNameWithRole(topicIndex, this._mentionsByTopic, names),
            isRTL,
          ),
          topicsTasks,
        };
      }),
    };
  }

  async resummarize() {
    try {
      if (!this._record) return;
      await summaryService.resummarize(this._record.id);
    } catch {
      this.showResummarizeError();
    }
  }

  async retranslate() {
    try {
      if (!this._record) return;
      await summaryService.retranslate(this._record.id);
    } catch {
      this.showRetranslateError();
    }
  }
}
