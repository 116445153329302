import { BackendService } from './backend-service';

class RedisService {
  async putString(value: string): Promise<string> {
    try {
      const options = {
        textContent: true,
      };

      return await BackendService.post('redis/string/set', value, options);
    } catch (err) {
      console.error('error in RedisService:', err);

      return '';
    }
  }

  async getString(value: string): Promise<string> {
    try {
      const params = {
        textContent: true,
      };

      return await BackendService.get(`redis/string/get?key=${value}`, params);
    } catch (err) {
      console.error('error in RedisService:', err);

      return '';
    }
  }
}

export const redisService = new RedisService();
