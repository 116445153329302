export interface IGroup {
  id: number;
  name: string;
  description: string;
  createdDate: string;
  // revision?: number;
  // changing?: boolean | null;
  // changeStartDate?: string | null;
}

export interface IGroupReport {
  index: number;
  name: string;
}

export class CGroup implements IGroup {
  id = -1;
  name = '';
  description = '';
  createdDate = '';
}
