import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import { CommonDialogUiStore, DataGrid, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import { fileToBase64 } from '../../common/utils';

import appColors from '../../app/app-colors';

import TemplateDialog, { ITemplateDialogResult } from './template-dialog';

import { contentBase64ToDoc } from './templates-utils';

import { CTemplate, ITemplate } from './i-template';

import { gAPP_STORE } from '@/app/app-store';

const templateFileTypes = ['.doc', '.docx'];

const TemplatesGrid = () => {
  const { t } = useTranslation();

  const [newTemplate, setNewTemplate] = useState<ITemplate | undefined>();
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | undefined>();
  const [openCreateTemplateDialog, setOpenCreateTemplateDialog] = useState(false);
  const [preview, setPreview] = useState<string>('');
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  useEffect(() => {
    return reaction(
      () => gAPP_STORE.getTemplateStore().gridStore.selectedRow,
      () => setSelectedTemplate(gAPP_STORE.getTemplateStore().gridStore.selectedRow),
    );
  }, []);

  const handleCloseTemplateDialog = useCallback((dialogResult: ITemplateDialogResult) => {
    setOpenCreateTemplateDialog(false);

    if (dialogResult.closeStatus === 1) {
      if (dialogResult.template.id !== -1) {
        gAPP_STORE.getTemplateStore().update(dialogResult.template);
      } else {
        gAPP_STORE.getTemplateStore().create(dialogResult.template);
      }
    }
  }, []);

  const handleDeleteTemplate = useCallback(() => {
    if (selectedTemplate) {
      if (window.confirm(t('templates.deleteConfirm'))) {
        gAPP_STORE.getTemplateStore().delete(selectedTemplate);
      }
    }
  }, [selectedTemplate, t]);

  const handleDownloadTemplate = useCallback(() => {
    if (selectedTemplate && selectedTemplate.template) {
      const templateBase64 = atob(selectedTemplate.template);
      const doc = contentBase64ToDoc(templateBase64, true);

      if (doc) {
        const outBlob = doc.getZip().generate({
          type: 'blob',
          mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        saveAs(outBlob, `${selectedTemplate.fileName}`);
        gAPP_STORE.getRecordTextStore().getDownLoadTemplate(selectedTemplate.templateName);
      }
    }
  }, [selectedTemplate]);

  const handleAddTemplate = useCallback(
    (files: File[]) => {
      if (files && files.length > 0) {
        const file: File = files[0];
        if (file) {
          const fileName = file.name;
          const fileExt = fileName.split('.').at(-1);

          // Проверка расширения файла
          if (!templateFileTypes.includes(`.${fileExt}`)) {
            toast.error(t('templates.badExtension'));

            return;
          }

          // Проверка длины имени файла
          if (fileName.length > gAPP_STORE.templateMaxFileName) {
            toast.error(
              `${t('templates.nameIsTooLong', { maxTemplateNameLength: gAPP_STORE.templateMaxFileName })}: ${fileName}`,
            );

            return;
          }

          // Преобразование файла в Base64
          fileToBase64(file).then((contentBase64: string) => {
            if (contentBase64.length > gAPP_STORE.templateMaxFileSize) {
              toast.error(
                `${t('templates.fileLimit', { maxTemplateFileSize: gAPP_STORE.templateMaxFileSize })}: ${fileName}`,
              );

              return;
            }

            try {
              // Преобразование base64 в документ для проверки
              const doc = contentBase64ToDoc(contentBase64, true);

              if (doc) {
                // Если документ корректен, создаем шаблон
                const template: ITemplate = new CTemplate();
                template.fileName = fileName;
                template.templateName = fileName;
                template.template = contentBase64;
                setNewTemplate(template);
                setOpenCreateTemplateDialog(true);
              } else {
                toast.error(t('templates.docParseError'));
              }
            } catch (e) {
              toast.error(t('templates.docParseError'));
            }
          });
        }
      }
    },
    [t],
  );

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      { type: 'text', text: `${t('templates.title')}`, pr: 1 },
      { type: 'divider', pl: 1 },
      {
        type: 'upload',
        text: 'add',
        fileTypes: templateFileTypes,
        icon: <AddIcon />,
        onClick: handleAddTemplate,
      },

      {
        type: 'button',
        text: 'delete',
        icon: <ClearRoundedIcon />,
        onClick: handleDeleteTemplate,
        color: 'red',
        pl: 1,
        disabled: gAPP_STORE.getTemplateStore().gridStore.selectedRow ? false : true,
      },
      { type: 'divider', pl: 2 },
      {
        type: 'button',
        text: 'download',
        icon: <GetAppIcon />,
        onClick: handleDownloadTemplate,
        disabled: gAPP_STORE.getTemplateStore().gridStore.selectedRow ? false : true,
      },
    ],
    [handleAddTemplate, handleDeleteTemplate, handleDownloadTemplate, t],
  );

  const contentBase64ToDocPreview = useCallback(async (contentBase64: string) => {
    try {
      const doc = contentBase64ToDoc(contentBase64);

      if (doc) {
        const docData = {
          createdBy: '\n {createdBy} \n',
          createdDate: '\n {createdDate} \n',
          meetingTitle: '\n {meetingTitle} \n',
          meetingDate: '\n {meetingDate} \n',
          fileName: '\n {fileName} \n',
          loadDate: '\n {loadDate} \n',
          transcriptionLanguage: '\n {transcriptionLanguage} \n',
          speakersCount: '\n {speakersCount} \n',
          speakers: [
            {
              name: '\n {name}',
              wordsCount: '\n {wordsCount}',
              speechDuration: '\n {speechDuration} \n',
            },
          ],
          transcription: [
            {
              time: '\n {time}',
              speaker: '\n {speaker}',
              phrase: '\n {phrase} \n',
            },
          ],
          translation: [
            {
              time: '\n {time}',
              speaker: '\n {speaker}',
              phrase: '\n {phrase} \n',
            },
          ],
          autoTranslation: [
            {
              time: '\n {time}',
              speaker: '\n {speaker}',
              phrase: '\n {phrase} \n',
            },
          ],
          chartNumberOfWords: '\n {chartNumberOfWords} \n',
          chartDuration: '\n {chartDuration} \n',
        };
        await doc.resolveData(docData);
        doc.render();
        const out = doc.getFullText();

        return out;
      }
    } catch (error) {
      console.error('failed to parse docx', error);
    }

    return '';
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      contentBase64ToDocPreview(atob(selectedTemplate.template)).then(result => setPreview(result));
    } else {
      setPreview('');
    }
  }, [contentBase64ToDocPreview, selectedTemplate]);

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1} flexDirection="row">
        <Box display="flex" flexGrow={1}>
          <DataGrid
            id="TemplatesGrid"
            cols={gAPP_STORE.getTemplateStore().gridCols}
            gridStore={gAPP_STORE.getTemplateStore().gridStore}
            inscriptions={gAPP_STORE.tableInscriptions}
            noPaging
          />
        </Box>

        <Box
          display="flex"
          flexGrow={0}
          maxWidth={600}
          maxHeight={1}
          style={{ background: appColors.grey, overflow: 'auto' }}
        >
          {selectedTemplate && preview.length > 0 && (
            <div style={{ minWidth: '400', maxHeight: 1 }}>
              <Box
                style={{
                  backgroundColor: 'white',
                  borderRadius: 20,
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
                m={'1em'}
                p={'1em'}
              >
                <Typography component="pre">{preview}</Typography>
              </Box>
            </div>
          )}
        </Box>
      </Box>

      {
        <TemplateDialog
          open={openCreateTemplateDialog}
          onClose={handleCloseTemplateDialog}
          template={newTemplate || selectedTemplate}
          uiStore={userDlgUiStoreRef.current}
        />
      }
    </Panel>
  );
};

export default observer(TemplatesGrid);
