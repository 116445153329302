import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { CommonDialog, CommonDialogUiStore, DataGridStore, ECommonDialogCloseStatus, useForceUpdate } from '@uk';

import { CWordDictionary, IWordDictionary } from './WordDictionary.types';
import { getWordDictionaryTypeValue, valueToWordsStr, wordsStrToValue } from './wordDictionaryUtils';

import { gAPP_STORE } from '@/app/app-store';
import { topicsRestrictions } from '@/common/constants';

export interface IWordDictionaryDialogResult {
  wordDictionary: IWordDictionary;
  oldValue?: IWordDictionary;
  closeStatus: ECommonDialogCloseStatus;
}

const useStyles = makeStyles(theme => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  option: {
    padding: 5,
  },
}));

const useLangList = (useFor: string): string[] => {
  const tsLangs = gAPP_STORE.getLanguagesStore().data.languages.map(l => l.name);
  const commonArray: string[] = [];
  tsLangs.forEach(tsl => {
    const langISO = gAPP_STORE.tServiceLangNameToISO(tsl);
    if (langISO) {
      commonArray.push(langISO.code);
    } else {
      console.error(`Unsupported language from T-Service: ${langISO}`);
    }
  });

  if (gAPP_STORE.viewTranslate && useFor === 'kws') {
    const autoTranslateLangs = gAPP_STORE.getAutoTranslateLanguagesStore().autoTranslateLanguages ?? [];
    commonArray.push(...autoTranslateLangs);
  }

  return Array.from(new Set(commonArray));
};

export interface IWordDictionaryProps {
  gridStore: DataGridStore<IWordDictionary>;
  wordDictionary: IWordDictionary | undefined;
  onClose: (dialogResult: IWordDictionaryDialogResult) => void;
}

enum ENameError {
  noError,
  errorEmpty,
  errorLong,
  errorDuble,
  errorTrim,
}

export const WordDictionaryDialog: React.FC<IWordDictionaryProps> = ({ gridStore, wordDictionary, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { t: lang } = useTranslation('lang');
  const forceUpdate = useForceUpdate();
  const [userDlgUiStore] = useState<CommonDialogUiStore>(() => new CommonDialogUiStore());

  const dialogResult: IWordDictionaryDialogResult = useMemo(() => {
    const res: IWordDictionaryDialogResult = {
      wordDictionary: wordDictionary ? { ...wordDictionary } : new CWordDictionary(),
      oldValue: wordDictionary,
      closeStatus: 0,
    };

    if (!gAPP_STORE.avocado_topics || !gAPP_STORE.avocado_wordsDictionary) {
      res.wordDictionary.isAutoAsr = gAPP_STORE.avocado_wordsDictionary;
      res.wordDictionary.isKws = gAPP_STORE.avocado_topics;
    }

    return res;
  }, [wordDictionary]);
  const { MAX_TOPIC_NAME, MAX_WORD_LENGTH, MAX_WORDS_COUNT } = topicsRestrictions;

  const checkName = useCallback(
    (name: string): ENameError => {
      const trimName = name.trim();

      if (name.length > 0 && trimName === '') return ENameError.errorTrim;

      if (trimName === '') return ENameError.errorEmpty;

      if (name !== trimName) return ENameError.errorTrim;

      if (trimName.length > MAX_TOPIC_NAME) return ENameError.errorLong;

      if (wordDictionary) {
        const curDicId = wordDictionary.id;
        if (
          wordDictionary.name.trim() !== trimName &&
          gridStore.data.some(dic => dic.id !== curDicId && dic.name.trim() === trimName)
        ) {
          return ENameError.errorDuble;
        }
      } else {
        if (gridStore.data.some(dic => dic.name.trim() === trimName)) return ENameError.errorDuble;
      }

      return ENameError.noError;
    },
    [MAX_TOPIC_NAME, gridStore.data, wordDictionary],
  );

  const checkWords = useCallback((s: string) => s.trim() === '', []);

  const [nameError, setNameError] = useState<ENameError>(ENameError.noError);
  const [wordsError, setWordsError] = useState(false);
  const [words, setWords] = useState('');

  useEffect(() => {
    const isNameValid = checkName(dialogResult.wordDictionary.name) === ENameError.noError;
    const isWordsValid = !wordsError && !checkWords(dialogResult.wordDictionary.value);

    userDlgUiStore.setOkEnabled(isNameValid && isWordsValid);
  }, [
    dialogResult.wordDictionary.name,
    dialogResult.wordDictionary.value,
    wordsError,
    userDlgUiStore,
    checkName,
    checkWords,
  ]);

  const [useFor, setUseFor] = useState<string>(getWordDictionaryTypeValue(dialogResult.wordDictionary));
  const langList = useLangList(useFor);

  useEffect(() => {
    setUseFor(getWordDictionaryTypeValue(dialogResult.wordDictionary));
    setWords('');
  }, [checkName, checkWords, dialogResult.wordDictionary]);

  const handleClose = useCallback(
    (status: number) => {
      dialogResult.closeStatus = status;
      switch (useFor) {
        case 'auto':
          dialogResult.wordDictionary.isAutoAsr = true;
          dialogResult.wordDictionary.isKws = false;
          break;
        case 'kws':
          dialogResult.wordDictionary.isAutoAsr = false;
          dialogResult.wordDictionary.isKws = true;
          break;
        case 'both':
          dialogResult.wordDictionary.isAutoAsr = true;
          dialogResult.wordDictionary.isKws = true;
          break;
      }

      if (status === ECommonDialogCloseStatus.CANCEL || status === ECommonDialogCloseStatus.OK) {
        dialogResult.closeStatus = status;
        if (onClose) {
          onClose(dialogResult);
        }
      }
    },
    [dialogResult, onClose, useFor],
  );

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      dialogResult.wordDictionary.name = newName;
      setNameError(checkName(newName));
      forceUpdate();
    },
    [dialogResult.wordDictionary, forceUpdate, checkName],
  );

  const handleLanguageChange = useCallback(
    event => {
      dialogResult.wordDictionary.language = event.target.value;
      forceUpdate();
    },
    [dialogResult.wordDictionary, forceUpdate],
  );

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputWords = event.target.value;
      const limitedText = inputWords
        .split(/\s+/)
        .slice(0, MAX_WORDS_COUNT)
        .map(word => word.slice(0, MAX_WORD_LENGTH))
        .join(' ');

      dialogResult.wordDictionary.value = wordsStrToValue(limitedText);
      setWordsError(checkWords(limitedText));
      forceUpdate();
    },
    [dialogResult.wordDictionary, MAX_WORDS_COUNT, MAX_WORD_LENGTH, forceUpdate, checkWords],
  );

  const DialogBody = useCallback(() => {
    return (
      <Box width={800}>
        <Box display="flex">
          <Box flexGrow={1}>
            {/* Name */}
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                key="name"
                label={t('wordDictionary.name')}
                variant="outlined"
                fullWidth
                defaultValue={dialogResult.wordDictionary.name}
                onChange={handleNameChange}
                autoComplete="off"
                inputProps={{ maxLength: MAX_TOPIC_NAME }}
                error={nameError !== ENameError.noError}
                helperText={
                  nameError === ENameError.errorEmpty
                    ? t('users.lengthError')
                    : nameError === ENameError.errorLong
                    ? t('users.lengthError')
                    : nameError === ENameError.errorDuble
                    ? t('users.uniqueNameError')
                    : nameError === ENameError.errorTrim
                    ? t('users.trimError')
                    : ''
                }
              />
            </FormControl>

            {/* Language */}
            <Box p={2} />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-language-label">{t('language')}</InputLabel>
              <Select
                labelId="select-language-label"
                id="select-language"
                value={dialogResult.wordDictionary.language}
                onChange={handleLanguageChange}
                label={t('language')}
              >
                {langList.map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {lang(code)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {gAPP_STORE.avocado_wordsDictionary && gAPP_STORE.avocado_topics && (
            <>
              <Box p={2} />
              <FormControl component="fieldset">
                <RadioGroup
                  value={useFor}
                  onChange={event => {
                    setUseFor(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="auto"
                    control={<Radio color="primary" />}
                    label={t('wordDictionary.isAutoAsr')}
                  />
                  <FormControlLabel value="kws" control={<Radio color="primary" />} label={t('wordDictionary.isKws')} />
                  <FormControlLabel
                    value="both"
                    control={<Radio color="primary" />}
                    label={t('wordDictionary.isAutoAsrAndKws')}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        </Box>

        <Box p={1} />

        {/* Words */}
        <Box p={1} />
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            key="description"
            label={t('wordDictionary.value')}
            variant="outlined"
            fullWidth
            multiline
            rows={20}
            value={words || valueToWordsStr(dialogResult.wordDictionary.value)}
            onChange={handleValueChange}
            onBlur={() => forceUpdate()}
            helperText={wordsError && t('users.lengthError')}
            error={wordsError}
            autoComplete="off"
          />
        </FormControl>
      </Box>
    );
  }, [
    classes.formControl,
    t,
    dialogResult.wordDictionary.name,
    dialogResult.wordDictionary.language,
    dialogResult.wordDictionary.value,
    handleNameChange,
    MAX_TOPIC_NAME,
    nameError,
    handleLanguageChange,
    langList,
    useFor,
    words,
    handleValueChange,
    wordsError,
    lang,
    forceUpdate,
  ]);

  return (
    <CommonDialog
      title={t('wordDictionary.title')}
      open={true}
      onClose={handleClose}
      contentComponent={DialogBody}
      uiStore={userDlgUiStore}
      autotestId={'wordDictEdit'}
    />
  );
};
