import { observer } from 'mobx-react';

import { CollapsePanel } from './common/CollapsePanel';
import { RolesPanel } from './RolesPanel';
import { ECollapsePanels, ETabStatus } from './summary-store';
import { TopicPanel } from './TopicPanel';

import { LoadingPanel } from '@/react-ui-kit/src';

import { gAPP_STORE } from '@/app/app-store';
import { SummaryDescription } from '@/components/summary/common/SummaryDescription';
import { NoDataPanel } from '@/components/summary/NoDataPanel';
import { TranslationErrorPanel } from '@/components/summary/TranslationErrorPanel';

export const BriefPanel = observer(() => {
  const { showSumMeeting, showSumBriefRoles, showSumBriefTopics, summaryStore } = gAPP_STORE;
  const {
    firstTabStatus,
    isTranslate,
    topics,
    summaryParticipants: participants,
    summaryMentions: mentions,
    isShow,
    switchShow,
    description,
  } = summaryStore;

  switch (firstTabStatus) {
    case ETabStatus.loading:
      return <LoadingPanel />;
    case ETabStatus.noData:
      return <NoDataPanel />;
    case ETabStatus.error:
      if (isTranslate) {
        return <TranslationErrorPanel />;
      }

      return <NoDataPanel />;

    default:
      return (
        <>
          {showSumMeeting && (
            <CollapsePanel
              key="panel1"
              id="item1"
              title="summary.accordionDescription"
              showPanel={isShow(ECollapsePanels.tab1Description)}
              switchShow={() => switchShow(ECollapsePanels.tab1Description)}
              component={
                <SummaryDescription text={description} id="DescriptionBluePaper" autotest="summaryDescription" />
              }
              autotest="summaryDescription"
            />
          )}
          {showSumBriefRoles && (
            <CollapsePanel
              key="panel2"
              id="item2"
              title="summary.accordionRoles"
              showPanel={isShow(ECollapsePanels.tab1Roles)}
              switchShow={() => switchShow(ECollapsePanels.tab1Roles)}
              component={
                <RolesPanel
                  canEdit={!summaryStore.isTranslate}
                  id="brief"
                  participants={participants}
                  mentions={mentions || ''}
                  divider
                  autotest="summaryParticipants"
                />
              }
              autotest="summaryParticipants"
            />
          )}
          {showSumBriefTopics && (
            <CollapsePanel
              key="panel3"
              id="item3"
              title="summary.accordionMeetingTopics"
              showPanel={isShow(ECollapsePanels.tab1Topics)}
              switchShow={() => switchShow(ECollapsePanels.tab1Topics)}
              component={
                <div style={{ display: 'flex', flexDirection: 'column', padding: '12px', gap: '12px' }}>
                  {topics.map((topic, index) => {
                    return (
                      <TopicPanel
                        key={index}
                        topic={topic}
                        isTranslate={summaryStore.isTranslate}
                        autotest={`summaryTopic-${index}`}
                      />
                    );
                  })}
                </div>
              }
              autotest="summaryTopics"
            />
          )}
        </>
      );
  }
});
