import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip, Fade, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';

import { FaFemale, FaMale } from 'react-icons/fa';

import appColors from '@/app/app-colors';
import { gAPP_STORE } from '@/app/app-store';
import { strToDatetimeStr } from '@/react-ui-kit/src/utils/dateUtils';

interface IInfoItemProps {
  title: string | ReactElement;
  value: string | number | ReactElement;
  isChangeStyle: boolean;
}

const InfoItem: FC<IInfoItemProps> = props => {
  const { title, value, isChangeStyle } = props;

  return (
    <Box
      style={
        isChangeStyle
          ? {
              width: '420px',
              overflow: 'hidden',
              overflowWrap: 'break-word',
            }
          : {}
      }
    >
      <Typography variant="body2" color="textSecondary">
        {title}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

interface IPlayerRecordInfoPanelProps {}

const PlayerRecordInfoPanel: FC<IPlayerRecordInfoPanelProps> = observer(props => {
  const { t } = useTranslation();
  const { t: lang } = useTranslation('lang');

  const { soundStore } = gAPP_STORE;
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const { record } = soundStore;

  const dictors = (record && dictorsStore.getDictorsWithSegmentationForRecord(record.correlationId)) || [];

  const maleDictors = dictors.filter(dictor => dictor.gender === 'male');
  const femaleDictors = dictors.filter(dictor => dictor.gender === 'female');
  const undefinedDictors = dictors.filter(dictor => dictor.gender === 'undefined');

  const data = record
    ? [
        {
          children: [
            {
              title: t('ID'),
              value: record.id,
            },
            {
              title: t('File name'),
              value: record.fileName,
            },
          ],
        },
        {
          children: [
            {
              title: t('records.dateLoad'),
              value: strToDatetimeStr(record.loadDate),
            },
            {
              title: t('records.dateCreate'),
              value: strToDatetimeStr(record.createdDate),
            },
          ],
        },
        {
          children: [
            {
              title: t('Language'),
              value: record ? lang(record.languageResolved) : '-',
            },
            {
              title: t('Number of speakers'),
              value: (
                <Box display="flex" alignItems="center">
                  {maleDictors.map((_, index) => (
                    <FaMale key={index} style={{ color: appColors.blue }} />
                  ))}
                  {femaleDictors.map((_, index) => (
                    <FaFemale key={index} style={{ color: appColors.red }} />
                  ))}
                  {undefinedDictors.map((_, index) => (
                    <FaMale key={index} style={{ color: appColors.darkGrey }} />
                  ))}
                  <Box px={0.5}>({dictors.length})</Box>
                </Box>
              ),
            },
          ],
        },
        {
          children: [
            {
              title: gAPP_STORE.avocado_topics ? t('Topics') : '',
              value: (
                <Box display="flex" flexWrap="wrap" style={{ gap: 2 }}>
                  {(record.topics ?? []).map((topic, index) => (
                    <Chip
                      key={index}
                      size="small"
                      color="primary"
                      label={`${topic.name} (${Math.floor(topic.kwsThreshold || 0)}%)`}
                    />
                  ))}
                </Box>
              ),
            },
          ],
        },
      ]
    : [];

  if (!record) {
    return null;
  }

  return (
    <Fade in>
      <Box display="flex" p={2} style={{ gap: '3em' }}>
        {data.map((column, columnIndex) => {
          if ((column.children?.length ?? 0) === 0) {
            return null;
          }

          return (
            <Box key={columnIndex} display="flex" flexDirection="column" style={{ gap: '0.5em' }}>
              {column.children.map((item, itemIndex) => {
                return (
                  <InfoItem
                    isChangeStyle={item.value.toString().length > 60}
                    key={itemIndex}
                    title={item.title}
                    value={item.value}
                  />
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Fade>
  );
});

export default PlayerRecordInfoPanel;
