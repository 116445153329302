import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { CommonDialog, CommonDialogUiStore, LoadingPanel } from '@uk';
import { observer } from 'mobx-react';
import moment from 'moment';
import 'moment-duration-format';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Z_INDEX_COMMON_DIALOG } from 'common/constants';

import { Chart } from '../chart/Chart';

import { DictorChip } from './DictorChip';

import appColors from '@/app/app-colors';
import { gAPP_STORE } from '@/app/app-store';
import { getShortTitleFromString, getTitle } from '@/components/dictors/dictor-utils';
import { IDictor } from '@/types/dictors';

const DialogBody = observer(() => {
  const { t } = useTranslation();

  const dictorsStore = gAPP_STORE.getDictorsStore();
  const selectedRecord = gAPP_STORE.getRecordsStore().gridStore.selectedRow;

  const dictors: IDictor[] =
    selectedRecord && dictorsStore.isSegmentationLoaded
      ? [...dictorsStore.getDictorsWithSegmentationForRecord(selectedRecord.correlationId)].sort(
          (a, b) => b.wordsCount - a.wordsCount,
        )
      : [];

  const numberOfWordsSeries = dictors.map(dictor => dictor.wordsCount);
  let totalWords = 0;
  numberOfWordsSeries.forEach(v => (totalWords += v));

  if (dictors.length === 0) {
    return null;
  }

  const cellProps = { style: { borderBottom: 'none', padding: 5, width: '250px' } };

  const speechSeries = dictors.map(dictor => dictor.speechDuration);

  const labels = dictors.map(dictor => getTitle(dictor));

  const colors = dictors.map(dictor => (dictor.segmentation.ranges.length > 0 ? dictor.__color : appColors.grey));

  const options = {
    chart: {
      type: 'donut',
    },
    labels,
    colors,

    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          dataLabels: {
            enabled: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
  };

  const tooltipDuration = {
    enabled: true,
    y: {
      formatter: (value: string) => moment.duration(value, 'seconds').format('hh:mm:ss', { trim: false }),
    },
  };

  const legend = {
    formatter: (value: string) => getShortTitleFromString(value),
  };

  return (
    <Box
      display="flex"
      alignItems="start"
      style={{
        gap: '8em',
        height: '70vh',
        overflow: 'auto',
      }}
    >
      <Box flexGrow={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell {...cellProps}>{t('Speakers')}</TableCell>
              <TableCell {...cellProps} align="right">
                {t('Number of words')}
              </TableCell>
              <TableCell {...cellProps} align="right">
                {t('Speech, sec')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {dictors.map((dictor, dictorIndex) => {
              return (
                <TableRow key={dictorIndex}>
                  <TableCell {...cellProps}>
                    <DictorChip popperStyle={{ zIndex: Z_INDEX_COMMON_DIALOG }} dictor={dictor} />
                  </TableCell>
                  <TableCell {...cellProps} align="right">
                    {dictor.wordsCount}
                  </TableCell>
                  <TableCell {...cellProps} align="right">
                    {moment.duration(dictor.speechDuration, 'seconds').format('hh:mm:ss', { trim: false })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <Box
        width={450}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        style={{
          gap: '2em',
        }}
      >
        <Box style={{ height: '40vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">{t('Number of words')}</Typography>
          <Box p={1} />
          <Chart options={{ ...options, legend }} series={numberOfWordsSeries} type="donut" />
        </Box>

        <Box style={{ height: '40vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">{t('Speech, sec')}</Typography>
          <Box p={1} />
          <Chart options={{ ...options, tooltip: tooltipDuration, legend }} series={speechSeries} type="donut" />
        </Box>
      </Box>
    </Box>
  );
});

interface IDictorsStatisticsDialogProps {
  open: boolean;
  onClose: () => void;
}

const DictorStatisticsDialog: React.FC<IDictorsStatisticsDialogProps> = props => {
  const { open, onClose } = props;
  const userDlgUiStoreRef = useRef<CommonDialogUiStore>(new CommonDialogUiStore());

  const { t } = useTranslation();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  if (gAPP_STORE.getDictorsStore().status === 'loading') {
    return <LoadingPanel />;
  }

  return (
    <CommonDialog
      style={{
        zIndex: Z_INDEX_COMMON_DIALOG,
        position: 'absolute',
      }}
      title={t('Speaker statistics')}
      open={open}
      onClose={handleClose}
      contentComponent={() => <DialogBody />}
      autotestId={'user'}
      uiStore={userDlgUiStoreRef.current}
      noCancel
    />
  );
};

export default observer(DictorStatisticsDialog);
