import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DataGrid, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';
import { Box } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

import { gAPP_STORE } from '@/app/app-store';

const LanguagesGrid = () => {
  const { t } = useTranslation();

  const handleLoadFromService = useCallback(() => {
    gAPP_STORE.getLanguagesStore().loadLanguages(['Transcribition', 'Identification']);
  }, []);

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(() => [
    { type: 'text', text: `${t('languages.title')}`, pr: 1 },
    { type: 'divider', pl: 1 },
    {
      type: 'button',
      tooltipId: 'languages.loadFromService',
      text: 'languages.loadFromService',
      icon: <CloudUpload />,
      onClick: handleLoadFromService,
      pl: 2,
    },
  ], [handleLoadFromService, t]);

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="LanguagesGrid"
          cols={gAPP_STORE.getLanguagesStore().gridCols}
          gridStore={gAPP_STORE.getLanguagesStore().gridStore}
          inscriptions={gAPP_STORE.tableInscriptions}
          isNeedTooltipForContent={true}
          noPaging
        />
      </Box>

    </Panel>
  );
};

export default observer(LanguagesGrid);
