// import loginStore from '@uk/components/login/login.store';
import { applyStateToDummy } from '@uk/services/dummy/dummyUtils';
import { IDataGridStoreData, IDataGridStoreState, TDataProvider } from '@uk/components/dataGrid';
import { IEntityStore } from './entityStore';

class EntityStoreHelper<TEntity> {
  entityStore: IEntityStore<TEntity>;
  entityDataProvider: TDataProvider<TEntity>;

  constructor(entityStore: IEntityStore<TEntity>, entityDataProvider: TDataProvider<TEntity>) {
    this.entityStore = entityStore;
    this.entityDataProvider = entityDataProvider;
  }

  reset = () => {
    this.entityStore.gridStore.reset();
    this.entityStore.tmpAddedEntityList = [];
    this.entityStore.tmpChangedEntityPairList = [];
    this.entityStore.tmpDeletedEntityList = [];
  };

  getEntityId = (entity: TEntity) => (entity as any)[this.entityStore.primaryFieldName];

  tmpAddEntity = (entity: TEntity) => {
    this.entityStore.tmpAddedEntityList.push(entity);
    this.entityStore.gridStore.loadData();
  };
  tmpChangeEntity = (oldEntity: TEntity, newEntity: TEntity) => {
    let changedEntityPair = this.entityStore.tmpChangedEntityPairList.find(
      (ce) => this.getEntityId(ce.old) === this.getEntityId(newEntity)
    );
    if (changedEntityPair) {
      changedEntityPair.new = newEntity;
    } else {
      changedEntityPair = { old: oldEntity, new: newEntity };

      console.log('******* ChangeEntity old', oldEntity);
      console.log('******* ChangeEntity new', newEntity);
      // if ("Id" in oldEntity){
      //   oldEntity.Id
      // }
      if ((oldEntity as any).id) {
        this.entityStore.tmpChangedEntityPairList.push(changedEntityPair);
      }
    }
    this.entityStore.gridStore.loadData();
  };
  tmpDeleteEntity = (entity: TEntity) => {
    if ('id' in (entity as any) || 'Identifier' in (entity as any)) {
      this.entityStore.tmpDeletedEntityList.push(entity);
    } else {
      this.entityStore.tmpAddedEntityList = this.entityStore.tmpAddedEntityList.filter(
        (e) => e !== entity
      );
    }
    // TODO gavr делать load data только если всего записей больше чем отображаемых
    // (есть больше одной сраницы данных)
    this.entityStore.gridStore.loadData();
  };

  // sm: Пока loginStore отключен

  dataProvider = async (
    state: IDataGridStoreState,
    entity?: TEntity
  ): Promise<IDataGridStoreData<TEntity>> => {
    const data = await applyStateToDummy(state, this.entityStore.dummyData);

    const dataWithChanges: TEntity[] = [];
    let dataWOChanges: TEntity[] = data.data;

    if (this.entityStore.tmpChangedEntityPairList.length !== 0) {
      this.entityStore.tmpChangedEntityPairList.forEach((pair) => {
        dataWOChanges = dataWOChanges.filter((d) => (d as any).id !== (pair.old as any).id);
        dataWithChanges.push(pair.new);
      });
    }

    const deletedIds = this.entityStore.tmpDeletedEntityList.map((e) => this.getEntityId(e));
    const resultData = [
      ...this.entityStore.tmpAddedEntityList,
      ...dataWithChanges,
      ...dataWOChanges,
    ].filter((e) => !deletedIds.includes(this.getEntityId(e)));

    return { data: resultData, total: resultData.length, hasNextPage: false };
  };
}

export default EntityStoreHelper;
