import { computed, makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import i18n from 'i18next';

import { ImageCacheType } from '../avatar/cached-avatar';
import { RecordsStore } from '../records/records-store';
import { IRecord } from '../records/types';

import { getTitle } from './dictor-utils';

import { EStoreStatus, TStatus } from '@/common/store-status';
import { UNKNOWN_SPEAKER } from '@/components/dictors/const';
import { SummaryStore } from '@/components/summary/summary-store';
import { dictorStatIndexService } from '@/services/dictor-stat-index/dictor-stat-index-service';
import { dictorsService } from '@/services/dictors/dictors-service';
import { BackendError } from '@/services/types';
import { IDictor } from '@/types/dictors';
import { TChannelNumber } from '@/types/segmentation-types';

export class DictorsStore {
  private _summaryStore: SummaryStore;
  private _recordsStore: RecordsStore;
  private _tmpDictorsWithSegmentation?: IDictor[];
  private _cacheDictorAvatars: ImageCacheType = {};
  private _segmentationUpdated = false;
  segmentationLoading = false;

  constructor(recordsStore: RecordsStore, summaryStore: SummaryStore) {
    this._recordsStore = recordsStore;
    this._summaryStore = summaryStore;
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  async reactionOnSelectRecord(record: IRecord | undefined) {
    this.clearCashDictorAvatars();
    await this.loadDictorsWithSegmantation(record?.correlationId);
  }

  //==============================================================================
  setSegmentationLoading(v: boolean) {
    this.segmentationLoading = v;
  }

  clearCashDictorAvatars() {
    this._cacheDictorAvatars = {};
  }

  get cacheDictorAvatars() {
    return this._cacheDictorAvatars;
  }

  //==============================================================================
  private _colorIdx = 1;

  get colorIdx() {
    return this._colorIdx;
  }

  private setColorIdx(value: number) {
    this._colorIdx = value;
  }

  //==============================================================================

  private _dictors: IDictor[] = [];

  get dictors() {
    return this._dictors;
  }

  setDictors(value: IDictor[]) {
    this._dictors = value;
  }

  @computed
  getDictorsByRecord(id: number): IDictor[] {
    //сегментация не загружена
    return this.dictors.filter(dictor => dictor.recordId === id);
  }

  @computed
  get dictorsForFilter(): Map<string, string> {
    const mapKnownDictors = new Map<string, string>();
    this.dictors
      .filter(dictor => !dictor.defaultName)
      .forEach(dictor => {
        if (dictor.extId && !mapKnownDictors.has(dictor.extId)) {
          mapKnownDictors.set(dictor.extId, getTitle(dictor));
        }
      });

    return mapKnownDictors;
  }

  filterToDictorIds(refIds: string[]): number[] {
    const result: number[] = [];
    this.dictors.forEach(dictor => {
      if (dictor.extId && refIds.includes(dictor.extId)) {
        result.push(dictor.id);
      }
    });

    return result;
  }

  private _segmentationLoaded = false;
  private _curentRecordCorrelationId?: string;
  private _currentRecordDictors: IDictor[] = [];

  public get isSegmentationLoaded() {
    return this._segmentationLoaded;
  }

  async loadDictorsWithSegmantation(correlationId?: string) {
    this._tmpDictorsWithSegmentation = undefined;
    this.setSegmentationLoading(true);
    this._segmentationLoaded = false;
    if (correlationId === undefined) {
      runInAction(() => {
        this._curentRecordCorrelationId = undefined;
        this._currentRecordDictors = [];
      });

      return;
    }
    try {
      let currentRecordDictors = await dictorsService.getDictorsForRecord(correlationId);
      currentRecordDictors = currentRecordDictors.sort((a, b) => a.id - b.id);
      currentRecordDictors.forEach((d, i) => (d.__orderNum = i));

      runInAction(() => {
        this._curentRecordCorrelationId = correlationId;
        this._currentRecordDictors = currentRecordDictors;
        this._segmentationLoaded = true;
      });
    } catch (e) {
      toast.error(i18n.t('BadRequest'));
      console.log(`Error loading dictors for current record. ${e}`);
    }
    this.setSegmentationLoading(false);
  }

  @computed
  getDictorsWithSegmentation = (): IDictor[] => {
    return this._currentRecordDictors;
  };

  @computed
  getDictorsWithSegmentationForRecord = (recordCorrelationId: string | undefined): IDictor[] => {
    if (recordCorrelationId === this._curentRecordCorrelationId) {
      return this._currentRecordDictors;
    } else {
      if (!this.hideSegmentation) {
        console.error('ask not loaded recordCorrelationId=', recordCorrelationId);
      }

      return [];
    }
  };

  @computed
  get dictorsCount() {
    return this._currentRecordDictors.length;
  }

  getTmpDictorsWithSegmentation = (): IDictor[] => {
    if (!this._segmentationLoaded) return [];
    //@@@?надо оставить ?
    if (this._tmpDictorsWithSegmentation === undefined) {
      this._tmpDictorsWithSegmentation = [...this._currentRecordDictors];
    }
    //@@@?

    return this._tmpDictorsWithSegmentation;
  };

  updateTmpDictors = (dictors: IDictor[]) => {
    this._tmpDictorsWithSegmentation = dictors;

    this._segmentationUpdated = true;
  };

  get segmentationUpdated() {
    return this._segmentationUpdated;
  }

  setSegmentationUpdated(value: boolean) {
    this._segmentationUpdated = value;
  }

  @computed
  get hasDictorChanges() {
    return this._tmpDictorsWithSegmentation !== undefined;
  }

  @computed
  getTmpDictorsForMenu = (idFirstDictor: number, channel?: TChannelNumber) => {
    const dictorsToShow = this._tmpDictorsWithSegmentation
      ? this.getTmpDictorsWithSegmentation()
      : this._currentRecordDictors;
    if (!dictorsToShow) {
      return [];
    }

    return channel === undefined
      ? dictorsToShow.filter(d => d.id !== idFirstDictor)
      : dictorsToShow.filter(d => d.channel === channel && d.id !== idFirstDictor);
  };

  //=======================================================================
  dictorNumByRecordId(dictor: IDictor) {
    return dictor.__orderNum === undefined ? -1 : dictor.__orderNum;
  }

  //=======================================================================

  private _isDictorsPanelVisible = false;

  get isDictorsPanelVisible() {
    return this._isDictorsPanelVisible;
  }

  setIsDictorsPanelVisible(value: boolean) {
    this._isDictorsPanelVisible = value;
  }

  //=======================================================================

  private _openDictorsStatisticsDialog = false;

  get isDictorsStatisticsDialogOpen() {
    return this._openDictorsStatisticsDialog;
  }

  setDictorsStatisticsDialogOpen(isValue: boolean) {
    this._openDictorsStatisticsDialog = isValue;
  }

  //=======================================================================

  isSettingsPanelOpen = false;

  setIsSettingsPanelOpen(v: boolean) {
    this.isSettingsPanelOpen = v;
  }

  //=======================================================================

  // Статус стора
  private _status: TStatus = 'empty';

  get status() {
    return this._status;
  }

  setStatus(value: TStatus) {
    this._status = value;
  }

  //=======================================================================
  // Скрытие фраз диктора (массив ид дикторов, которых надо скрыть в расшифровке)
  private _hidePhrases: number[] = [];
  get hidePhrases() {
    return this._hidePhrases;
  }

  setHidePhrases(value: number[]) {
    this._hidePhrases = value;
  }

  toggleHidePhrases(dictorId: number) {
    runInAction(() => {
      if (this.hidePhrases.includes(dictorId)) {
        this.setHidePhrases(this.hidePhrases.filter(elem => elem !== dictorId));
      } else {
        this.setHidePhrases([...this.hidePhrases, dictorId]);
      }
    });
  }

  //=======================================================================
  // Скрытие сегментации диктора (массив ид дикторов, которых надо скрыть в плеере)
  private _hideSegmentation: number[] = [];

  get hideSegmentation() {
    return this._hideSegmentation;
  }

  setHideSegmentation(value: number[]) {
    this._hideSegmentation = value;
  }

  toggleHideSegmentation(dictorId: number) {
    runInAction(() => {
      if (this.hideSegmentation.includes(dictorId)) {
        this.setHideSegmentation(this.hideSegmentation.filter(elem => elem !== dictorId));
      } else {
        this.setHideSegmentation([...this.hideSegmentation, dictorId]);
      }
    });
  }

  resetHide() {
    this.setHideSegmentation([]);
    this.setHidePhrases([]);
  }

  //=======================================================================

  async clearSearchResult(dictor: IDictor) {
    dictor.name = `${UNKNOWN_SPEAKER} ${dictor.dictorNum}`;
    dictor.surname = '';
    dictor.avatar = '';
    dictor.extId = null;
    dictor.relevance = undefined;
    dictor.defaultName = true;
    await this.updateDictorWithGroups(dictor);
  }

  //=======================================================================
  async updateDictor(dictor: IDictor) {
    try {
      const result = await dictorsService.updateDictor(dictor);

      runInAction(() => {
        const newDictorsList = this.dictors.filter(d => d.id !== dictor.id);
        newDictorsList.push(result);
        this.setDictors([...newDictorsList]);

        if (this._summaryStore.openSummaryPanel) {
          this._summaryStore.updateDictorsInfo();
        }
      });
    } catch (e) {
      toast.error(i18n.t('BadRequest'));
      console.log(`Error update dictor. ${e}`);
    }
  }

  async updateDictorWithGroups(dictor: IDictor) {
    try {
      this.setStatus('loading');

      const result = await dictorsService.updateDictorWithGroups(dictor);
      await this.loadDictorsWithSegmantation(dictor.correlationId);

      runInAction(() => {
        const newDictorsList = this.dictors.filter(d => d.id !== dictor.id);
        newDictorsList.push(result);
        this.setDictors([...newDictorsList]);
        this.setStatus('success');
      });
    } catch (e) {
      toast.error(i18n.t('BadRequest'));
      console.log(`Error update dictor. ${e}`);
      this.setStatus('error');
    }
  }

  //=======================================================================
  async reloadRecordDictors(record: IRecord) {
    if (this.status === 'loading') {
      return;
    }
    try {
      this.setStatus('loading');

      await dictorStatIndexService.waitForReloadDictorStatIndex(record.id, record.transcriptionId);
      await this.loadDictorsWithSegmantation(record.correlationId);
      //закоментированный код понадобиться, когда мы при разделении дикторов будем добавлять новую каточку
      // const result = await dictorsService.getByRecordIds([record.id]);

      // runInAction(() => {
      //   const newDictorsList = this.dictors.filter(d => !result.find(dd => dd.id === d.id));
      //   newDictorsList.push(...result);
      //   this.setDictors([...newDictorsList]);
      // });
      this.setStatus('success');
    } catch (e) {
      toast.error(i18n.t('BadRequest'));
      console.log(`Error reload record dictors. ${e}`);
      this.setStatus('error');
    }
  }

  async reloadDictors() {
    if (this.status === 'loading') {
      return;
    }
    try {
      this.setStatus('loading');

      const result = await dictorsService.getDictors();

      runInAction(() => {
        this.setDictors(result);
        this.setStatus('success');
      });
    } catch (e) {
      this.setStatus('error');
      if (e instanceof BackendError && e.status === 401) {
        toast.error(i18n.t(e.statusMessage));
      } else {
        toast.error(i18n.t('BadRequest'));
        console.log(`Error loading all dictors list. ${e}`);
      }
    }
  }

  async deleteDictor(dictorId: number) {
    try {
      this.setStatus('loading');

      await dictorsService.deleteDictor(dictorId);

      runInAction(() => {
        this._currentRecordDictors = this._currentRecordDictors.filter(d => d.id !== dictorId);
        const newDictorsList = this.dictors.filter(d => d.id !== dictorId);
        this.setDictors([...newDictorsList]);
      });
    } catch (e) {
      toast.error(`Error delete dictor: ${JSON.stringify(e)}`);
      console.log(`Error delete dictor. ${e}`);
    } finally {
      this.setStatus('success');
    }
  }

  async reloadStore() {
    if (this.status === 'loading') {
      return;
    }
    try {
      this.setStatus('loading');

      const result = await dictorsService.getDictors();

      runInAction(() => {
        this.setHidePhrases([]);
        this.setHideSegmentation([]);
        this.setDictors(result);
        this.setStatus('success');
      });
    } catch (e) {
      this.setStatus(EStoreStatus.ERROR);
      if (e instanceof BackendError && e.status === 401) {
        toast.error(i18n.t(e.statusMessage));
      } else {
        toast.error(i18n.t('BadRequest'));
        console.log(`Error loading all dictors list. ${e}`);
      }
    }
  }
}
