import { Dispatch, FC, SetStateAction } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';

import { gAPP_STORE } from '@/app/app-store';
import { TLangCode } from '@/stores/translationStore';

interface ILangSelectorProps {
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  anchorEl: null | HTMLElement;
}

export const LangSelector: FC<ILangSelectorProps> = observer(props => {
  const { anchorEl, setAnchorEl } = props;
  const open = Boolean(anchorEl);
  const availableLocales = gAPP_STORE.availableLocales;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (selected: TLangCode) => {
    gAPP_STORE.translationStore.switchLanguage(selected);
    gAPP_STORE.updateTableLabels();
    handleClose();
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        style={{ marginTop: '40px' }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {availableLocales.map(l => (
          <MenuItem key={l} onClick={() => changeLanguage(l)}>
            {l.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});
