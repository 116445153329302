import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { Chip, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface IFilterFieldListItem {
  id: string | number | string[] | number[];
  title: string;
}

export const strListToFilterFieldList = (list: string[]): IFilterFieldListItem[] =>
  list.map(v => ({ id: v, title: v }));

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  endAdornment: {
    padding: 3,
  },

  autocomplete: {
    flexGrow: 1,
  },
  select: {
    flexGrow: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderLeft: '1px solid #ddd',
  },
  option: {
    padding: 10,
  },
});

interface IFilterFieldListProps {
  name: string;
  value: string[];
  list?: (string | IFilterFieldListItem)[];
  score?: number;
  onChange: (name: string, value: string[], score?: number) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  noTranslateValue?: boolean;
  multiple?: boolean;
  maxValues?: number;
}

export const FilterFieldList: React.FC<IFilterFieldListProps> = props => {
  const { name, value, score, list, onChange, noTranslateValue, onKeyDown, multiple = true, maxValues } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentVal, setCurrentVal] = useState(
    list ? list?.filter(v => typeof v === 'string' || (value || []).includes(String(v.id))) : value,
  );

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setCurrentVal(list ? list?.filter(v => typeof v === 'string' || (value || []).includes(String(v.id))) : value);
  }, [list, value]);

  const renderInput = useCallback(params => {
    params.InputProps = { ...params.InputProps, disableUnderline: true };
    params.inputProps = { ...params.inputProps, style: { padding: 5 } };

    return <TextField {...params} />;
  }, []);

  const scoreValues: number[] = [0];
  for (let i = 0; i < 20; i++) {
    scoreValues.push((i + 1) * 5);
  }
  const onDeleteChip = useCallback(
    (val: string | IFilterFieldListItem) => {
      const localVal = val === undefined || Array.isArray(val) ? val : [val];
      if (localVal) {
        const deleted = currentVal.filter(v => v !== val);
        setCurrentVal(deleted);
        if (onChange) {
          onChange(
            name,
            deleted.map(v => (typeof v === 'string' ? v : String(v.id))),
            score,
          );
        }
      }
    },
    [currentVal, name, onChange, score],
  );

  const renderTags = useCallback(
    (val: (IFilterFieldListItem | string)[], getTagProps: AutocompleteGetTagProps) => {
      return currentVal.map((v, index) => {
        const title = typeof v === 'string' ? v : (v as IFilterFieldListItem).title;

        return (
          <Chip
            key={index}
            label={noTranslateValue === true ? title : t(title)}
            color="primary"
            size={'small'}
            style={{ margin: '0.5em', overflow: 'hidden' }}
            onDelete={() => onDeleteChip(v)}
          />
        );
      });
    },
    [currentVal, noTranslateValue, onDeleteChip, t],
  );

  const getOptionLabel = useCallback(
    (option: string | IFilterFieldListItem) => {
      if (!option) {
        return '';
      }

      const label = typeof option === 'string' ? option : option.title;
      if (noTranslateValue === true) {
        return label;
      } else {
        return t(label);
      }
    },
    [noTranslateValue, t],
  );

  const handleValueChange = (event: React.ChangeEvent<object>, newValue: (string | IFilterFieldListItem)[]) => {
    let enteredValue = newValue;

    if (list) {
      const allowedTitles = new Set((list || []).map(item => (typeof item === 'string' ? item : item.title)));

      enteredValue = enteredValue.filter(value => {
        const title = typeof value === 'string' ? value : value.title;

        return allowedTitles.has(title);
      });
    }

    if (!multiple && Array.isArray(enteredValue) && enteredValue.length > 1) {
      enteredValue = enteredValue.slice(0, 1);
    }

    if (maxValues && maxValues < enteredValue.length) {
      enteredValue = enteredValue.slice(0, maxValues);
    }

    setCurrentVal(enteredValue);

    if (onChange && Array.isArray(enteredValue)) {
      onChange(
        name,
        enteredValue.map(v => (typeof v === 'string' ? v : String(v.id))),
        score,
      );
    }
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} onMouseLeave={handleMouseLeave}>
      <Autocomplete
        ChipProps={{ size: 'small', color: 'primary' }}
        className={classes.autocomplete}
        classes={{
          endAdornment: classes.endAdornment,
        }}
        clearText={t('filter.clear')}
        noOptionsText={t('filter.noOptions')}
        multiple
        freeSolo
        fullWidth
        options={list || []}
        getOptionLabel={v => getOptionLabel(v)}
        value={currentVal}
        defaultValue={[]}
        renderInput={renderInput}
        renderTags={renderTags}
        onChange={handleValueChange}
        onKeyDown={onKeyDown}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
      {score !== undefined && (
        <Select
          className={classes.select}
          disableUnderline
          value={score}
          onChange={event => {
            const newScore = event.target.value as number;
            if (onChange) onChange(name, value || [], newScore);
          }}
        >
          {scoreValues.map((d, index) => (
            <MenuItem key={index} className={classes.option} value={d}>
              {d === 0 ? t('all') : `>= ${d}`}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
};
