import { FilterTranslationStatusList, RecordStatusMap } from 'components/records/types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import React, { useCallback, useEffect, useMemo } from 'react';

import { cloneDeep } from 'lodash';

import * as yup from 'yup';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { FilterCommon } from '../common/common-filter';

import { checkTrim } from '../records/utils/checkTrim';

import { handleFilterDate } from '../records/utils/handleFilterDate';

import { getDefaultFilter, getFilterDescriptor } from './filter-descriptor';
import { IFilterFieldListItem, strListToFilterFieldList } from './filter-field-list';
import { FILTER_DATA_RELATIONS, TFilterDataFields } from './i-filter';
import { IFilterField, TFilterFieldValue, TFilterPredicate } from './i-filter-field';

import { gAPP_STORE } from '@/app/app-store';
import { ESpecialLanguageCode, ILanguage } from '@/components/languages/i-language';

const defaultLanguagesInFilter: string[] = [
  ESpecialLanguageCode.EN,
  ESpecialLanguageCode.ES,
  ESpecialLanguageCode.RU,
  ESpecialLanguageCode.KK,
];

type TFilterFields =
  | 'status'
  | 'translationStatus'
  | 'owner'
  | 'caseid'
  | 'fileName'
  | 'loadFrom'
  | 'loadTo'
  | 'createFrom'
  | 'createTo'
  | 'languageResolved'
  | 'textIdx'
  | 'translateIdx'
  | 'meetingParticipants'
  | 'dictors'
  | 'speechDuration'
  | 'wordsCount'
  | 'topic'
  | 'topicThreshold'
  | 'summaryText'
  | 'summaryInstructions'
  | 'summaryTopics';

interface IFilterProps {}

export const Filter: React.FC<IFilterProps> = observer(() => {
  const { t } = useTranslation();
  const dictorsStore = gAPP_STORE.getDictorsStore();
  const filterStore = gAPP_STORE.getFilterStore();

  const langT = useTranslation('lang')['t'];
  const formSchema = useMemo(
    () =>
      yup.object({
        status: yup.array().max(5),
        translationStatus: yup.array().max(5),
        owner: yup.array().max(5),
        caseid: yup.array(yup.string().max(1200).min(1)).max(10), //название встречи
        fileName: yup.array(yup.string().max(1200).min(1)).max(10),
        loadFrom: yup
          .string()
          .optional()
          .test(function (loadFrom) {
            if (!loadFrom) return true;

            const { loadTo } = this.parent;
            const notBiggerThanTo = loadTo ? new Date(loadFrom) <= new Date(loadTo) : true;
            const notBiggerThanNow = new Date(loadFrom) <= new Date();

            return notBiggerThanTo && notBiggerThanNow;
          }),
        loadTo: yup
          .string()
          .optional()
          .test(function (loadTo) {
            if (!loadTo) return true;

            const { loadFrom } = this.parent;
            const notBiggerThanFrom = loadFrom ? new Date(loadFrom) <= new Date(loadTo) : true;
            const notBiggerThanNow = new Date(loadTo) <= new Date();

            return notBiggerThanFrom && notBiggerThanNow;
          }),
        createFrom: yup
          .string()
          .optional()
          .test(function (createFrom) {
            if (!createFrom) return true;

            const { createTo } = this.parent;
            const notBiggerThanTo = createTo ? new Date(createFrom) <= new Date(createTo) : true;
            const notBiggerThanNow = new Date(createFrom) <= new Date();

            return notBiggerThanTo && notBiggerThanNow;
          }),
        createTo: yup
          .string()
          .optional()
          .test(function (createTo) {
            if (!createTo) return true;

            const { createFrom } = this.parent;
            const notBiggerThanFrom = createFrom ? new Date(createFrom) <= new Date(createTo) : true;
            const notBiggerThanNow = new Date(createTo) <= new Date();

            return notBiggerThanFrom && notBiggerThanNow;
          }),
        languageResolved: yup.array(yup.string().test(checkTrim)).max(10).optional(),
        textIdx: yup.array(yup.string().max(100).required()).max(10).optional(), //расшифровка
        translateIdx: yup.array(yup.string().max(100).required()).optional().max(10).optional(), //перевод
        meetingParticipants: yup.array(yup.string().max(100)).optional(), //количество дикторов
        dictors: yup.array().max(10).optional(),
        speechDuration: yup.array(yup.string().max(100)).optional(),
        wordsCount: yup.array(yup.string().max(100)).optional(),
        topic: yup.array(yup.string()).max(10).optional(),
        topicThreshold: yup.number().optional(),
        summaryText: yup.array(yup.string().min(1).max(1200)).optional(), //обзор совещания
        summaryInstructions: yup.boolean().optional(),
        summaryTopics: yup.array(yup.string().min(1).max(1200)).optional(), //на повестке дня
      }),
    [],
  );

  const {
    register,
    reset,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  });

  useEffect(() => {
    filterStore.setFilterData(getDefaultFilter());
  }, [filterStore]);

  const setFilterData = useCallback(
    f => {
      filterStore.setFilterData(f());
    },
    [filterStore],
  );

  const applyFilter = useCallback(() => {
    gAPP_STORE.getRecordTextStore().clear();
    gAPP_STORE.getRecordsStore().reload();
    gAPP_STORE.getRecordsStore().gridStore.setCurrentPage(0);
    gAPP_STORE.getRecordsStore().gridStore.resetSelected();
  }, []);

  const handleFilterClear = useCallback(() => {
    setFilterData(() => {
      setFilterData(getDefaultFilter);
      reset();
      applyFilter();

      return filterStore.Data;
    });
  }, [applyFilter, filterStore.Data, reset, setFilterData]);

  const getOwnersList = useCallback(() => {
    return gAPP_STORE.getUsersStore().data.users.map(u => ({ id: u.id.toString(), title: u.firstName }));
  }, []);
  const getTopicsList = useCallback(() => {
    return gAPP_STORE
      .getWordDictionaryStore()
      .data.wordDictionaryList.filter(wd => wd.isKws)
      .map(wd => ({ id: wd.id.toString(), title: wd.name }));
  }, []);
  const menuInstructions = useMemo(() => {
    const itemYes = t('records.summaryHaveTaskYes');
    const itemNo = t('records.summaryHaveTaskNo');

    return [
      { id: 'summaryHaveTaskYes', title: itemYes },
      { id: 'summaryHaveTaskNo', title: itemNo },
    ];
  }, [t]);
  const getInsructionsList = useCallback(() => {
    return menuInstructions;
  }, [menuInstructions]);
  const getDictorsList = useCallback(() => {
    const dictorNameList = dictorsStore.dictorsForFilter;
    const menuList: IFilterFieldListItem[] = [];
    dictorNameList.forEach((value: string, key: string) => {
      menuList.push({ id: key, title: value });
    });
    menuList.sort((a, b) => a.title.localeCompare(b.title));

    return menuList;
  }, [dictorsStore.dictorsForFilter]);

  const localizeLanguagesList = useCallback(
    (list: (string | ILanguage)[]): IFilterFieldListItem[] => {
      return list
        .map(l => {
          const isoLang = gAPP_STORE.tServiceLangNameToISO(typeof l === 'string' ? l : l.name);
          if (isoLang && isoLang.code) {
            return {
              id: `language.${isoLang.code}`,
              title: langT(isoLang.code),
            } as IFilterFieldListItem;
          }

          return { id: '', title: 'error' };
        })
        .filter(f => f.id !== '');
    },
    [langT],
  );

  const getLanguagesList = useCallback(() => {
    let items = localizeLanguagesList(gAPP_STORE.getLanguagesStore().data.languages.filter(l => l.installed));
    if (!items || items.length === 0) {
      console.warn('Attention! T-Service languages undefined! No languages in gAPP_STORE.LanguagesStore.');
      items = localizeLanguagesList(defaultLanguagesInFilter);
    }

    return items;
  }, [localizeLanguagesList]);
  const handleFilterChange = useCallback(
    async (id: string, pureValue: TFilterFieldValue | null | undefined, score?: number) => {
      //#region Validation
      //#region date
      const prevValue = getValues()[id as TFilterFields];
      let value = pureValue;
      const relatedFields: { id: TFilterDataFields; value: IFilterField }[] = [];
      const relatedKey = FILTER_DATA_RELATIONS[id as TFilterDataFields];

      if (typeof value === 'string' && id === 'loadFrom') {
        const to: string | undefined = relatedKey && control._formValues[relatedKey];
        const date = handleFilterDate({ from: value, to });
        date.from && (value = date.from);
        const filterField = relatedKey && cloneDeep(filterStore.Data[relatedKey]);
        if (date.to && filterField) {
          filterField.value = date.to;
          setValue(relatedKey as TFilterFields, filterField.value as string);
          relatedKey && relatedFields.push({ id: relatedKey, value: filterField });
        }
      }
      if (typeof value === 'string' && id === 'loadTo') {
        const from: string | undefined = relatedKey && control._formValues[relatedKey];
        const date = handleFilterDate({ from, to: value });
        date.to && (value = date.to);
        const filterField = relatedKey && cloneDeep(filterStore.Data[relatedKey]);
        if (date.from && filterField) {
          filterField.value = date.from;
          setValue(relatedKey as TFilterFields, filterField.value as string);
          relatedKey && relatedFields.push({ id: relatedKey, value: filterField });
        }
      }

      if (typeof value === 'string' && id === 'createFrom') {
        const to: string | undefined = relatedKey && control._formValues[relatedKey];
        const date = handleFilterDate({ from: value, to });
        date.from && (value = date.from);
        const filterField = relatedKey && cloneDeep(filterStore.Data[relatedKey]);
        if (date.to && filterField) {
          filterField.value = date.to;
          setValue(relatedKey as TFilterFields, filterField.value as string);
          relatedKey && relatedFields.push({ id: relatedKey, value: filterField });
        }
      }
      if (typeof value === 'string' && id === 'createTo') {
        const from: string | undefined = relatedKey && control._formValues[relatedKey];
        const date = handleFilterDate({ from, to: value });
        date.to && (value = date.to);
        const filterField = relatedKey && cloneDeep(filterStore.Data[relatedKey]);
        if (date.from && filterField) {
          filterField.value = date.from;
          setValue(relatedKey as TFilterFields, filterField.value as string);
          relatedKey && relatedFields.push({ id: relatedKey, value: filterField });
        }
      }

      //#endregion
      setValue(id as TFilterFields, value as string);
      await trigger([id as TFilterFields]);

      if (control.getFieldState(id as TFilterFields).invalid && !relatedFields.length) {
        filterStore.onFilterFieldChange(id as TFilterFields);
        setValue(id as TFilterFields, prevValue);

        return;
      }

      const fieldsToTrim = ['textIdx', 'translateIdx', 'summaryText', 'summaryTopics'];
      let handledValue = value;

      if (fieldsToTrim.includes(id) && Array.isArray(value)) {
        handledValue = value.map(item => item.trim()).filter(Boolean);
      } else if (Array.isArray(value)) {
        handledValue = value.flatMap(item => item.split(',').map(s => s.trim()));
      }

      //#endregion

      setFilterData(() => {
        const newFilterData = cloneDeep(filterStore.Data);
        relatedFields.forEach(relatedField => {
          newFilterData[relatedField.id] = relatedField.value;
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        if (Array.isArray(value)) {
          let goodvalues: string[];
          switch (id) {
            case 'status':
              goodvalues = Array.from(RecordStatusMap.values());
              break;
            case 'translationStatus':
              const arr = Array.from(RecordStatusMap.values());
              goodvalues = arr.splice(1, arr.length - 2);
              break;
            case 'languageResolved':
              goodvalues = getLanguagesList().map(item => String(item.id));
              break;
            case 'owner':
              goodvalues = getOwnersList().map(item => item.id);
              break;
            case 'dictors':
              goodvalues = getDictorsList().map(item => String(item.id));
              break;
            case 'summaryText':
              goodvalues = value.slice(0, 20);
              break;

            case 'topic':
              goodvalues = getTopicsList().map(item => item.id);
              break;

            default:
              goodvalues = value;
          }
          newFilterDataField.value = value.filter(v => goodvalues.includes(v));
        } else {
          newFilterDataField.value = value;
        }
        newFilterDataField.score = score;

        return newFilterData;
      });
    },
    [
      control,
      getTopicsList,
      filterStore,
      getDictorsList,
      getLanguagesList,
      getOwnersList,
      getValues,
      setFilterData,
      setValue,
      trigger,
    ],
  );

  const handleFilterPredicateChange = useCallback(
    (id: string, value: TFilterPredicate) => {
      setFilterData(() => {
        const newFilterData = filterStore.Data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newFilterDataField: any = newFilterData[id as TFilterDataFields]
          ? newFilterData[id as TFilterDataFields]
          : {};

        newFilterDataField.predicate = value;

        return newFilterData;
      });
    },
    [filterStore.Data, setFilterData],
  );

  const handleFilterApply = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        applyFilter();
      }
    },
    [applyFilter],
  );

  const getNoTranslateValue = useCallback((descriptor: IFilterField): boolean | undefined => {
    let result = undefined;
    if (descriptor.id === 'dictors' || descriptor.id === 'topic') {
      result = true;
    }

    return result;
  }, []);

  const getFilterFieldList = useCallback(
    (descriptor: IFilterField): IFilterFieldListItem[] => {
      let result: IFilterFieldListItem[] = [];

      switch (descriptor.id) {
        case 'status':
          result = strListToFilterFieldList(
            Array.from(RecordStatusMap.values()).filter(v => !['recordStatus.unknown'].includes(v)),
          );
          break;

        case 'translationStatus':
          result = strListToFilterFieldList(
            Array.from(RecordStatusMap.values()).filter(v => FilterTranslationStatusList.includes(v)),
          );
          break;

        case 'languageResolved':
          result = getLanguagesList();
          break;

        case 'owner':
          result = getOwnersList();
          break;

        case 'topic':
          result = getTopicsList();
          break;

        case 'dictors':
          result = getDictorsList();
          break;

        case 'summaryInstructions':
          result = getInsructionsList();
          break;
      }

      return result;
    },
    [getLanguagesList, getOwnersList, getTopicsList, getDictorsList, getInsructionsList],
  );

  const filterFields = useMemo(() => {
    let f = gAPP_STORE.viewTranslate
      ? getFilterDescriptor()
      : getFilterDescriptor().filter(d => d.id !== 'translationStatus' && d.id !== 'translateIdx');
    if (!gAPP_STORE.avocado_topics) {
      f = f.filter(field => !['topic', 'topicThreshold'].includes(field.id));
    }

    if (!gAPP_STORE.gridProxyUrl || gAPP_STORE.gridProxyUrl.length <= 0) {
      f = f.filter(field => !['dictors'].includes(field.id));
    }

    if (!gAPP_STORE.showSummaryAI) {
      f = f.filter(field => !['summaryText', 'summaryInstructions', 'summaryTopics'].includes(field.id));
    }

    return f;
  }, []);

  return (
    <>
      <FilterCommon
        getFilterFieldList={getFilterFieldList}
        filterFields={filterFields}
        handleFilterChange={handleFilterChange}
        handleFilterPredicateChange={handleFilterPredicateChange}
        handleKeyDown={handleKeyDown}
        getNoTranslateValue={getNoTranslateValue}
        filterData={filterStore.Data}
        handleFilterApply={handleFilterApply}
        handleFilterClear={handleFilterClear}
      />
    </>
  );
});
