import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';

import { toast } from 'react-toastify';

import { observer } from 'mobx-react';

import DataSourceCreateDialog, { ISourceDialogResult } from './data-source-create-dialog/data-source-create-dialog';

import DialogDeleteSource from './data-source-delete-dialog/data-source-delete-dialog';

import { gAPP_STORE } from '@/app/app-store';
import { DataGrid, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@/react-ui-kit/src';

export const DataSourcesPanel = observer(() => {
  const { t } = useTranslation();
  const [openCreateSourceDialog, setOpenCreateSourceDialog] = useState(false);
  const [openEditSourceDialog, setOpenEditSourceDialog] = useState(false);
  const [openDeleteSourceDialog, setOpenDeleteSourceDialog] = useState(false);

  const { selectedRow } = gAPP_STORE.getDataSourcesStore().gridStore;

  const handleCreateSource = useCallback(() => {
    setOpenCreateSourceDialog(true);
  }, []);

  const handleEditSource = useCallback(() => {
    if (selectedRow) {
      setOpenEditSourceDialog(true);
    }
  }, [selectedRow]);

  const handleDeleteSource = useCallback(() => {
    if (selectedRow) {
      setOpenDeleteSourceDialog(true);
    }
  }, [selectedRow]);

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      { type: 'text', text: `${t('dataSources.title')}`, pr: 1 },
      { type: 'divider', pl: 1 },
      {
        type: 'button',
        text: 'create',
        icon: <AddIcon />,
        onClick: handleCreateSource,
        pl: 1,
      },
      {
        type: 'button',
        text: 'edit',
        icon: <EditIcon />,
        onClick: handleEditSource,
        disabled: !selectedRow,
        pl: 1,
      },
      {
        type: 'button',
        text: 'delete',
        icon: <DeleteIcon />,
        onClick: handleDeleteSource,
        disabled: !selectedRow,
        pl: 1,
      },
    ],
    [handleCreateSource, handleDeleteSource, handleEditSource, t, selectedRow],
  );

  const handleCloseSourceDialog = (dialogResult: ISourceDialogResult) => {
    if (lodash.isEqual(dialogResult.source, dialogResult.oldValue)) {
      setOpenCreateSourceDialog(false);
      setOpenEditSourceDialog(false);

      return;
    }

    if (dialogResult.closeStatus === 1 && dialogResult.source?.settings?.groups?.length === 0) {
      toast.error(t('groups.errorEmptyName'));

      return;
    }

    if (
      dialogResult.closeStatus === 1 &&
      gAPP_STORE.maxCiscoChannels <= gAPP_STORE.getDataSourcesStore().data.sources.length
    ) {
      return toast.warn(t('dataSources.errors.maxChannels'));
    }

    if (dialogResult.closeStatus === 1) {
      if (dialogResult?.source?.id !== -1) {
        // Update group
        dialogResult.source && gAPP_STORE.getDataSourcesStore().update(dialogResult.source);
      } else {
        // Create group
        dialogResult.source && gAPP_STORE.getDataSourcesStore().create(dialogResult.source);
      }
    }
    setOpenCreateSourceDialog(false);
    setOpenEditSourceDialog(false);
  };

  const handleCloseSourceDeleteDialog = (dialogResult: ISourceDialogResult) => {
    setOpenDeleteSourceDialog(false);
    if (dialogResult.closeStatus === 1) {
      const selectedSource = gAPP_STORE.getDataSourcesStore().gridStore.selectedRow;
      if (selectedSource) {
        gAPP_STORE.getDataSourcesStore().delete(selectedSource);
      }
    }
  };

  useEffect(() => {
    return () => {
      gAPP_STORE.getDataSourcesStore().gridStore.setSelectedRow(undefined);
    };
  }, []);

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="DataSourcesGrid"
          cols={gAPP_STORE.getDataSourcesStore().gridCols}
          gridStore={gAPP_STORE.getDataSourcesStore().gridStore}
          inscriptions={gAPP_STORE.tableInscriptions}
          isNeedTooltipForContent={true}
          noPaging
        />
      </Box>

      <DataSourceCreateDialog
        open={openCreateSourceDialog || openEditSourceDialog}
        onClose={handleCloseSourceDialog}
        source={openEditSourceDialog ? selectedRow : undefined}
      />
      <DialogDeleteSource open={openDeleteSourceDialog} onClose={handleCloseSourceDeleteDialog} source={selectedRow} />
    </Panel>
  );
});
