import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import { BackendService } from 'services';

import { gAPP_STORE } from '@/app/app-store';
import { LOCAL_STORAGE_USER_KEY } from '@/common/constants';
import { EStoreStatus } from '@/common/store-status';
import { IUser } from '@/components/users/types';
import { userBackToFront, userFrontToBack } from '@/components/users/user-utils';
import { gridProxyService } from '@/services/grid-proxy/grid-proxy-service';
import { BackendError, HandleErrorTranslatedMessage } from '@/services/types';
import { userService } from '@/services/user/user-service';

export class LoginStore {
  user: IUser | undefined = undefined;
  login = '';
  password = '';
  status: EStoreStatus = EStoreStatus.EMPTY;
  smsCode = '';
  @observable
  mfaConfig?: boolean;

  @observable
  timeout?: number;

  error = '';
  cacheKey?: string;

  constructor(cacheKey?: string) {
    this.cacheKey = cacheKey;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @computed getAuthorizationString = () =>
    `Basic ${btoa(unescape(encodeURIComponent(`${this.login}:${this.password}`)))}`;

  @action checkUserSession = () => {
    const userSession = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || '{}');
    if (userSession.user && Date.now() - userSession.datetime < 1000 * 60 * 30) {
      this.user = userBackToFront(userSession.user);
      this.saveUserSession();
    }
  };

  @action getAuthorizeJwtToken = async (token: string) => {
    try {
      const user = await userService.getUserDataViaJWT(token);
      runInAction(() => {
        this.user = user;
      });
      this.saveUserSession();
    } catch {
      this.signOut();
      this.checkUserSession();
    }
  };

  @action updateGroups = async () => {
    if (!this.user) return;
    try {
      const data = await BackendService.post('groups/users', JSON.stringify([this.user.id]));

      this.user.groups = data ? data[this.user.id] : [];
    } catch (error) {
      this.user.groups = undefined;
      if (error instanceof BackendError) {
        if (error.status !== 204) {
          toast.error(HandleErrorTranslatedMessage(error));
        } else {
          this.user.groups = [];
        }
      } else {
        console.error(error);
      }
    }
  };

  async mfaInit() {
    try {
      const data = await BackendService.get('auth/mfa/config');
      if (data) {
        this.setMfaConfig(data.isMfaEnabled, data.timeout);
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  setMfaConfig(value: boolean, timeout?: number) {
    this.mfaConfig = value;
    this.timeout = timeout;
  }

  /**
   * generateSMSCode
   */

  @action generateSMSCode = async (login: string) => {
    try {
      this.login = login;
      const options = {
        sessionId: '',
      };

      const response = await BackendService.post('auth/mfa/generate', JSON.stringify({ username: login }), options);

      if (response === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Error in generateSMSCode:', e);

      return false;
    }
  };

  /**
   * Login
   */
  @action
  signIn = async (login: string, smsCode: string, password: string) => {
    try {
      this.clearUserSession();
      gAPP_STORE.setStatusCodeError(0);

      this.error = '';
      this.login = login;
      this.smsCode = smsCode;
      this.password = password;
      this.status = EStoreStatus.LOADING;
      const user = await userService.getUserDataViaAuth(login, smsCode, password);

      runInAction(() => {
        this.user = user;
        this.status = EStoreStatus.SUCCESS;
        this.saveUserSession();
      });

      gAPP_STORE.getRecordTextStore().restoreState();

      return true;
    } catch (error) {
      console.error('LoginStore, signIn(), error = ', JSON.stringify(error));

      this.error = JSON.stringify(error);
      this.user = undefined;
      this.status = EStoreStatus.ERROR;
    }

    return false;
  };

  @computed isSignedIn = () => this.user !== undefined;

  @action signOut = () => {
    const params = {
      noPrefix: true,
    };
    BackendService.get('logout', params); //back чистит cookie SESSION !!!!
    this.clearUserSession();
  };

  restoreUserSession = async () => {
    if (this.cacheKey) {
      try {
        gAPP_STORE.dataGridAnswer = await gridProxyService.getGidAnswer(this.cacheKey);

        if (gAPP_STORE.dataGridAnswer) {
          await this.getAuthorizeJwtToken(gAPP_STORE.dataGridAnswer.Jwt);

          return;
        }
      } catch (e) {
        console.error('restoreUserSession:', e);
      }
    } else {
      this.checkUserSession();
    }
  };

  saveUserSession = () => {
    localStorage.setItem(
      LOCAL_STORAGE_USER_KEY,
      JSON.stringify({
        user: this.user ? userFrontToBack(this.user) : undefined,
        datetime: Date.now(),
      }),
    );
  };

  @action clear() {
    this.user = undefined;
    this.login = '';
    this.password = '';
    this.status = EStoreStatus.EMPTY;
  }
  clearUserSession = () => {
    this.clear();
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  };
}
