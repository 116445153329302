import { makeAutoObservable } from 'mobx';

import { ICycle, PlayerStore } from '../player/Player.store';

import { IRecord } from './types';

export class RecordSoundStore {
  record?: IRecord = undefined;
  position = 0;
  duration = 0;
  isPlaying = false;
  isCycling = false;
  isEditMode = false;
  needLoadToPlayer = false;
  playerStore?: PlayerStore;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setRecord = (f: IRecord) => {
    this.needLoadToPlayer = true;
    this.record = f;
    this.position = 0;
  };

  jumpPlayer(p: number) {
    this.getPlayerStore()?.jumpto(p);
  }

  setPosition = (p: number) => {
    if (p < this.position || p > this.position + 0.1) {
      this.position = p;
    }
  };

  setEditMode = (edit: boolean) => {
    this.isEditMode = edit;
  };

  setDuration = (d: number) => {
    this.duration = d;
  };

  setIsPlaying = (playing: boolean) => {
    this.isPlaying = playing;
  };

  setIsCycling = (cycling: boolean) => {
    this.isCycling = cycling;
  };

  setNeedLoadToPlayer = (needLoadToPlayer: boolean) => {
    this.needLoadToPlayer = needLoadToPlayer;
  };

  // setPlayerRect = (playerRect: DOMRect) => {
  //   this.playerRect = playerRect;
  // };

  setPlayerStore = (store: PlayerStore | undefined) => {
    this.playerStore = store;
  };

  getPlayerStore = () => {
    return this.playerStore;
  };

  setCycleRange(value: ICycle | undefined) {
    this.getPlayerStore()?.setCycleRange(value);
  }

  resetRecord = () => {
    this.record = undefined;
    this.position = 0;
    // this.playerRect = undefined;
    this.duration = 0;
    this.isPlaying = false;
    this.needLoadToPlayer = false;
  };
}
