import { Box, IconButton, makeStyles, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { Clear, Info } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { LabelChip } from '../../Labels/LabelChip';
import { DialogStore } from '../dialog.store';

import { Stack } from './Stack';

import { ILabel } from '@/components/records';
import { dialogUploadRecordsRestrictions } from '@/common/constants';

const useStyles = makeStyles({
  requiredLabel: {
    position: 'relative',
    '&:after': {
      content: '"*"',
      color: 'red',
      marginLeft: '4px',
    },
  },
});

interface ISelectedLabelsProps {
  typeIds: string[];
  allLabels: ILabel[];
}

const SelectedLabels = (props: ISelectedLabelsProps) => {
  const { typeIds, allLabels } = props;
  const foundLabels = allLabels.filter(l => typeIds.includes(l.typeId));
  if (!foundLabels) return null;

  return (
    <Stack spacing={0} direction="row">
      {foundLabels.map(label => (
        <Box p={0.5} key={`selected-${label.typeId}`}>
          <LabelChip label={label} />
        </Box>
      ))}
    </Stack>
  );
};

export interface IItemLabelProps {
  store: DialogStore;
}

export const ItemLabel = observer((props: IItemLabelProps) => {
  const { store } = props;
  const { labels, setLabels, resetLabels, allLabels } = store;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onLabelChange: SelectInputProps['onChange'] = e => {
    if (!Array.isArray(e.target?.value)) return;

    if (e.target.value.length > dialogUploadRecordsRestrictions.MAX_LABEL_COUNT) {
      toast.warn(t('uploadForm.maxLabels', { max_labels: dialogUploadRecordsRestrictions.MAX_LABEL_COUNT }));

      return;
    }

    const value: string[] = e.target.value.filter(Boolean);
    const foundLabels = allLabels.filter(l => value.includes(l.typeId));
    if (foundLabels.length) {
      setLabels(foundLabels);

      return;
    }
    resetLabels();
  };

  const closeMenu = () => setIsMenuOpen(false);
  const openMenu = () => setIsMenuOpen(true);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography className={classes.requiredLabel} data-autotest="ItemLabelTypesComboBoxLabel">
          {t('labelForm.labelName')}
        </Typography>
        <Tooltip
          title={
            <>
              <Typography>{t('labelForm.labelName')}</Typography>
              <Typography style={{ color: '#FFFFFFA3' }}>{t('uploadForm.descriptionLabels')}</Typography>
            </>
          }
        >
          <Info color="disabled" />
        </Tooltip>
      </Stack>
      <Box position="relative">
        <Select
          open={isMenuOpen}
          onOpen={openMenu}
          onClose={closeMenu}
          MenuProps={{
            onClick: closeMenu,
          }}
          variant="outlined"
          fullWidth
          multiple
          renderValue={v => v && <SelectedLabels allLabels={allLabels} typeIds={v as string[]} />}
          value={labels?.map(l => l.typeId) || []}
          onChange={onLabelChange}
          required
        >
          {allLabels.map(label => (
            <MenuItem key={label.typeId} value={label.typeId}>
              <LabelChip label={label} />
            </MenuItem>
          ))}
        </Select>
        {!!labels?.length && (
          <Box position="absolute" right="0" top="0">
            <IconButton color="default" size="small" onClick={resetLabels}>
              <Clear />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
});
