import { IBackUser, IUser } from './types';

export const userFrontToBack = (user: IUser): IBackUser => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    active: user.active,
    platform: user.platform,
    hashTag1: user.isAdmin,
    hashTag2: user.isSupervisor,
    hashTag3: user.isEditor,
    hashTag4: user.isServiceUser,
    hashTag5: user.isAD,
  };
};

export const userBackToFront = (user: IBackUser): IUser => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    active: user.active,
    platform: user.platform,
    isAdmin: user.hashTag1,
    isSupervisor: user.hashTag2,
    isEditor: user.hashTag3,
    isServiceUser: user.hashTag4,
    isAD: user.hashTag5,
  };
};
