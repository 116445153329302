import { toast } from 'react-toastify';
import { dateToISOString } from '@uk/utils/dateUtils';
import { makeAutoObservable, runInAction } from 'mobx';

import { BackendService, tserviceBackendApiUrl } from 'services';

import { DataGridStore, IDataGridStoreData, IDataGridStoreState } from '@uk';

import i18n from 'i18next';

import { languagesGridColumns } from './languages-grid-columns';

import { ILanguage } from './i-language';

import { BackendError } from '@/services/types';

const PurposeServers = {
  Transcribition: 'trscr',
  Identification: 'ident',
};
export type TLanguagePurpose = keyof typeof PurposeServers;

export class LanguagesStore {
  data: { languages: ILanguage[] } = { languages: [] };
  searchQuery = '';

  gridStore: DataGridStore<ILanguage>;
  gridCols = languagesGridColumns;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    const dataProvider = async (state: IDataGridStoreState): Promise<IDataGridStoreData<ILanguage>> => {
      await this.getAllImpl();

      return { data: this.data.languages, total: this.data.languages.length, hasNextPage: false };
    };

    this.gridStore = new DataGridStore<ILanguage>('LanguageSettings', this.gridCols, { dataProvider: dataProvider });
  }

  /**
   * Get all languages
   */
  getAll = async () => {
    await this.gridStore.reload();
  };

  private getAllImpl = async () => {
    try {
      const data = await BackendService.get('settings/system/all');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const languagesItem = data.find((item: any) => item.key === 'languages');
      runInAction(() => {
        this.data.languages = languagesItem ? JSON.parse(languagesItem.value) : [];
        this.data.languages = this.data.languages.sort((a: ILanguage, b: ILanguage) =>
          a.id === b.id ? 0 : a.id - b.id,
        );
      });
    } catch (error) {
      this.data.languages = [];
      if (error instanceof BackendError && error.status === 401) {
        toast.error(i18n.t(error.statusMessage));
      } else {
        toast.error(i18n.t('BadRequest'));
        throw error;
      }
    }
  };

  /**
   * Create new language
   */
  create = async (language: ILanguage) => {
    try {
      language.id =
        this.data.languages && this.data.languages.length > 0 ? Math.max(...this.data.languages.map(l => l.id)) + 1 : 1;
      language.modifyedDate = dateToISOString(new Date()) || '';
      await this.update(language);
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  /**
   * Update language
   */
  update = async (language: ILanguage) => {
    try {
      language.modifyedDate = dateToISOString(new Date()) || '';
      const languages = (this.data.languages || []).filter(l => l.id !== language.id);
      languages.push(language);
      await this.updateLanguages(languages);
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  /**
   * Delete language
   */
  delete = async (language: ILanguage) => {
    try {
      const languages = (this.data.languages || []).filter(l => l.id !== language.id);

      await this.updateLanguages(languages);
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  updateLanguages = async (languages: ILanguage[]) => {
    try {
      const data = (await BackendService.get('settings/system/all')) || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const languagesExists = data.some((item: any) => item.key === 'languages');

      if (!languagesExists) {
        await BackendService.put(
          'settings/system/add',
          JSON.stringify({ key: 'languages', value: JSON.stringify(languages) }),
        );
      } else {
        await BackendService.post(
          'settings/system/update',
          JSON.stringify({ key: 'languages', value: JSON.stringify(languages) }),
        );
      }

      this.gridStore.reload();
    } catch (error) {
      toast.error(i18n.t('BadRequest'));
      throw error;
    }
  };

  private async getLanguages(purpose: TLanguagePurpose) {
    const url = `${tserviceBackendApiUrl}/query/${PurposeServers[purpose]}/supported-languages`;
    try {
      const params = {
        noPrefix: true,
      };
      const data = await BackendService.get(url, params);

      return data.languages ?? [];
    } catch (e) {
      console.error('Error getting Indent Languages:', JSON.stringify(e));
    }
  }

  private langNamesToLanguages(langs: ILanguage[], names: string[], modifyedDate: string, installed: boolean) {
    let nextFreeLanguageId = langs.length;
    names.forEach(name => {
      langs.push({
        id: nextFreeLanguageId++,
        name,
        modifyedDate,
        installed,
        icon: '',
      });
    });
  }

  async loadLanguages(purposes: TLanguagePurpose[]) {
    if (purposes.length === 0) return;
    const tasks: Promise<string[]>[] = [];
    purposes.forEach(p => tasks.push(this.getLanguages(p)));
    const results = await Promise.allSettled(tasks);

    const data = dateToISOString(new Date());
    const newLanguages: ILanguage[] = [];
    let langTranscribition: string[] = [];
    if (results[0].status === 'fulfilled' && results[0].value && Array.isArray(results[0].value)) {
      langTranscribition = results[0].value;
    }
    this.langNamesToLanguages(newLanguages, langTranscribition, data, true);

    let langIdentification: string[] = [];
    if (results[1].status === 'fulfilled' && results[1].value && Array.isArray(results[1].value)) {
      langIdentification =
        langTranscribition.length === 0
          ? results[1].value
          : results[1].value.filter(name => !langTranscribition.includes(name));
    }
    this.langNamesToLanguages(newLanguages, langIdentification, data, false);

    this.updateLanguages(newLanguages);
  }
}
