import { Box } from '@material-ui/core';
import { FC } from 'react';

import { gAPP_STORE } from '@/app/app-store';

const pjson = require('./../../../package.json');

export const AppVersion: FC = () => {
  return (
    <Box pl={1} fontSize="0.6em">
      {gAPP_STORE.APP_VERSION || pjson.version}
    </Box>
  );
};
