import { CSSProperties, FC, MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
//import { reaction } from 'mobx';
import { ClickAwayListener, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { Add, Delete, Edit, Search } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';

import { Z_INDEX_POPPER } from 'common/constants';

import { DictorChipStore } from './DictorChipStore';

import { gAPP_STORE } from '@/app/app-store';

export interface IDictorChipMenuProps {
  dictorChipStore: DictorChipStore;
  editMode: boolean;
  isDictorActionPermit: boolean;
  changeEditMode: (newEditMode?: boolean) => void;
  clearResults: () => void;
  createCard: () => void;
  searchSpeakers: () => void;
  style?: CSSProperties;
}

export const DictorChipMenu: FC<IDictorChipMenuProps> = observer(props => {
  const { dictorChipStore, isDictorActionPermit, changeEditMode, clearResults, createCard, searchSpeakers, style } =
    props;
  const { segmentationUpdated } = gAPP_STORE.getDictorsStore();
  const { anchorEl, openMenu, setOpenMenu, dictor } = dictorChipStore;

  const MenuPaper = styled(Paper)({ borderRadius: 20, width: 320, maxWidth: '100%' });
  const MenuItemStyled = styled(MenuItem)({ paddingTop: 0, paddingBottom: 0 });

  const unknownDictor = useMemo(() => dictor?.extId === null, [dictor?.extId]);

  const { t } = useTranslation();

  const menu = useMemo(() => {
    if (gAPP_STORE.gridProxyUrl && gAPP_STORE.gridProxyUrl.length > 0) {
      return [
        {
          title: 'Create card',
          icon: <Add />,
          action: createCard,
          disabled: segmentationUpdated || !isDictorActionPermit || !unknownDictor,
        },
        {
          title: 'Search in registry',
          icon: <Search />,
          action: searchSpeakers,
          disabled: segmentationUpdated || !isDictorActionPermit || !unknownDictor,
        },
        {
          title: 'Delete search result',
          icon: <Delete />,
          action: clearResults,
          disabled: segmentationUpdated || !isDictorActionPermit || unknownDictor,
        },
        {
          title: 'EditTitle',
          icon: <Edit />,
          action: changeEditMode,
          disabled: segmentationUpdated || !isDictorActionPermit || !unknownDictor,
        },
      ];
    }

    return [
      {
        title: 'EditTitle',
        icon: <Edit />,
        action: changeEditMode,
        disabled: !isDictorActionPermit || !unknownDictor,
      },
    ];
  }, [
    changeEditMode,
    clearResults,
    createCard,
    isDictorActionPermit,
    searchSpeakers,
    unknownDictor,
    segmentationUpdated,
  ]);

  const handleClickMenu = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      setOpenMenu(false);
    },
    [setOpenMenu],
  );

  const fireMenuAction = useCallback(
    (event: MouseEvent<HTMLLIElement>, customAction?: () => void, title?: string) => {
      if (!dictor?.disabledForEditing && customAction) {
        customAction();
      } else if (dictor?.disabledForEditing) {
        toast.error(t(`MenuTextError.${title}`));
      }

      handleClickMenu(event);
    },
    [dictor?.disabledForEditing, handleClickMenu, t],
  );

  return (
    <Popper
      open={openMenu}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={style ? style : { zIndex: Z_INDEX_POPPER }}
    >
      <MenuPaper>
        <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
          <MenuList onMouseLeave={() => setOpenMenu(false)}>
            {menu.map((item, index) => (
              <MenuItemStyled
                key={index}
                onClick={event => fireMenuAction(event, item.action, item.title)}
                disabled={dictor?.disabledForEditing ? false : item.disabled}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{t(item.title)}</ListItemText>
              </MenuItemStyled>
            ))}
          </MenuList>
        </ClickAwayListener>
      </MenuPaper>
    </Popper>
  );
});
