import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';

import { LabelChip } from './dialogs/Labels/LabelChip';
import { ILabel, IRecord } from './types';

const RecordLabels = ({ record }: { record: IRecord }) => {
  if (!Array.isArray(record.labelLists)) {
    return <></>;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      {record.labelLists.map((label: ILabel, index) => (
        <Box key={label.id + label.name} m={0.5}>
          <LabelChip label={label} />
        </Box>
      ))}
    </Box>
  );
};

export default observer(RecordLabels);
