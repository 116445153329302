import i18n from 'i18next';

export interface ISendOptions {
  uploadRecord?: boolean;
  textContent?: boolean;
  noPrefix?: boolean;
  sessionId?: string;
  jwt?: string;
  isRedirect?: boolean;
  noAuth?: boolean;
  urlParams?: string;
  noCache?: boolean;
}

export interface IBackendErrorInfo {
  description: string;
  stackTrace: string;
  reasonCode?: string;
}
export class BackendError {
  constructor(public statusMessage: string, public status: number, public errorInfo: IBackendErrorInfo) {}
}

export const HandleErrorTranslatedMessage = (error: BackendError) => {
  if (error.errorInfo.description.startsWith('###')) {
    return i18n.t(error.errorInfo.description.replace('###', ''));
  } else if (error.errorInfo.reasonCode?.startsWith('###')) {
    return i18n.t(error.errorInfo.reasonCode.replace('###', ''));
  }

  return error.errorInfo.description;
};
