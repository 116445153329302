export interface IHandleFilterDateProps {
  from?: string;
  to?: string;
}
export const handleFilterDate = (props: IHandleFilterDateProps): IHandleFilterDateProps => {
  const { from, to } = props;
  //MUTABLE
  const result: { from?: string; to?: string } = { from: undefined, to: undefined };
  //MUTABLE
  const dateTo = to && new Date(to);
  const dateFrom = from && new Date(from);
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString();
  const fromBiggerThanNow = dateFrom ? dateFrom > currentDate : false;
  const fromBiggerThanTo = dateTo && dateFrom ? dateFrom > dateTo : false;
  const toBiggerThanNow = dateTo ? dateTo > currentDate : false;
  if (from) {
    fromBiggerThanNow && (result.from = currentDateString);
    fromBiggerThanTo && (result.to = currentDateString);
  }
  if (to) {
    toBiggerThanNow && (result.to = currentDateString);
  }

  return result;
};
