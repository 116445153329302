import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { ClickAwayListener } from '@material-ui/core';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import { useStyles } from '../record-text';
import { isOnlySpecialCharsSelected } from '../utils/dictorCutUtils';
import { IPhrase, IWord } from '..';

import { getPhraseWordText } from './record-text-utils';

import SelectButton from './select-button';

import { IChangeDictorInfo, ISelectionInfo } from './types';

import { gAPP_STORE } from '@/app/app-store';
import { EDirection } from '@/components/languages/i-language';

export interface IPhraseEditedProps {
  phraseIndex: number;
  phrase: IPhrase;
  stereo: boolean;
  isSignleDictorPhrase: boolean;
  canDictorChange: boolean;
  phraseDirection: EDirection;
  handleEditableFocused: (phraseIndex: number) => void;
  handleEditComplete: (
    event: React.ChangeEvent<HTMLDivElement>,
    phraseIndex: number,
    changeDictor?: IChangeDictorInfo,
  ) => void;
  handleEditKeyDown: (event: React.KeyboardEvent, phraseIndex: number) => void;
}

const getXFromRange = (range?: Range) => {
  if (!range) {
    return 0;
  }
  const len = range.getClientRects().length;

  return range.getClientRects()[len - 1].x + range.getClientRects()[len - 1].width;
};

const getYFromRange = (range?: Range) => {
  if (!range) {
    return 0;
  }

  const len = range.getClientRects().length;

  return range.getClientRects()[len - 1].y + range.getClientRects()[len - 1].height;
};

export const PhraseEdited: FC<IPhraseEditedProps> = ({
  phrase,
  phraseIndex,
  stereo,
  isSignleDictorPhrase,
  canDictorChange,
  phraseDirection,
  handleEditableFocused,
  handleEditComplete,
  handleEditKeyDown,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showEditDictorButton, setShowEditDictorButton] = useState(false);
  const [selectedRange, setSelectedRange] = useState<ISelectionInfo | null>(null);
  const editedElement = useRef<HTMLDivElement>(null);
  const recordTextStore = gAPP_STORE.getRecordTextStore();

  const modDivOutEdit = useMemo(
    () =>
      clsx(
        classes.phraseLine,
        phrase.channelNumber === 0 && (stereo || phraseDirection !== 'rtl')
          ? classes.phraseLine_left
          : classes.phraseLine_right,
      ),
    [
      classes.phraseLine,
      classes.phraseLine_left,
      classes.phraseLine_right,
      phrase.channelNumber,
      phraseDirection,
      stereo,
    ],
  );

  const text = useMemo(() => phrase.tokens.map((t: IWord) => getPhraseWordText(t)).join(' '), [phrase.tokens]);

  const modDivIn = useMemo(
    () =>
      clsx(
        classes.phrase,
        classes.edited_phrase,
        phrase.channelNumber === 0 ? classes.phrase_left : classes.phrase_right,
        {
          [classes.phrase_rtl]: recordTextStore.currentTextDirection === 'rtl',
        },
      ),
    [
      classes.edited_phrase,
      classes.phrase,
      classes.phrase_left,
      classes.phrase_right,
      classes.phrase_rtl,
      phrase.channelNumber,
      recordTextStore.currentTextDirection,
    ],
  );

  const handleSelect = useCallback((event: SyntheticEvent<HTMLDivElement, Event>) => {
    const selection = window.getSelection();
    const range = selection?.rangeCount && selection?.rangeCount > 0 && selection?.getRangeAt(0);
    if (range && range.startOffset < range.endOffset) {
      const allText = range.commonAncestorContainer.textContent ?? '';
      const selectedText = allText.slice(range.startOffset, range.endOffset);
      setSelectedRange({ range: range, selectedText: selectedText, allText: allText });
    } else {
      setSelectedRange(null);
    }
  }, []);

  const dictorsStore = gAPP_STORE.getDictorsStore();

  const haveDictorsToShow = useMemo(
    () => (phrase.dictor ? dictorsStore.getTmpDictorsForMenu(phrase.dictor.id, phrase.dictor.channel) : []).length > 0,
    [dictorsStore, phrase.dictor],
  );

  useEffect(() => {
    if (selectedRange) {
      let showWarning = false;
      if (isSignleDictorPhrase && haveDictorsToShow) {
        const range = selectedRange.range;
        const allText = selectedRange.allText;
        const beforeText = allText.slice(0, range.startOffset).trim();
        const afterText = allText.slice(range.endOffset, allText.length).trim();
        const text = selectedRange.selectedText.trim();
        const existOnlySpecialCharsPart =
          (beforeText === '' ? false : isOnlySpecialCharsSelected(beforeText)) ||
          (text === '' ? false : isOnlySpecialCharsSelected(text)) ||
          (afterText === '' ? false : isOnlySpecialCharsSelected(afterText));
        showWarning = text === '' || (beforeText === '' && afterText === '') || existOnlySpecialCharsPart;
      }
      if (showWarning) {
        toast.warn(`${t('dictorEdit.invalidSelection')}`, { toastId: 111 });
      } else {
        setShowEditDictorButton(true);
      }
    } else {
      setShowEditDictorButton(false);
    }
  }, [isSignleDictorPhrase, selectedRange, t]);

  const onClickAway = useCallback(() => {
    if (showEditDictorButton) {
      setShowEditDictorButton(false);
      const sel = window.getSelection();
      sel?.removeAllRanges();

      setTimeout(() => editedElement.current && editedElement.current.focus(), 0);

      return;
    }
    if (editedElement.current) {
      handleEditComplete({ target: editedElement.current } as ChangeEvent<HTMLDivElement>, phraseIndex);
    }
  }, [handleEditComplete, phraseIndex, showEditDictorButton]);

  useEffect(() => {
    window.addEventListener('blur', onClickAway);

    return () => window.removeEventListener('blur', onClickAway);
  }, [onClickAway]);

  useEffect(() => {
    if (editedElement.current) {
      setTimeout(() => editedElement.current && editedElement.current.focus(), 0); //some hack to focus editable with edit mode UNKNOWN BEHAVIOR
    }
  }, [editedElement]);

  // console.log('PHRASE RENDER: ', selectedRange?.range.getBoundingClientRect());

  return (
    <div key={phraseIndex} className={modDivOutEdit} tabIndex={-1}>
      <ClickAwayListener onClickAway={onClickAway}>
        <div style={{ position: 'relative', zIndex: 80 }}>
          <div
            ref={editedElement}
            contentEditable={true}
            suppressContentEditableWarning={true} //not managed by react in fact
            className={modDivIn}
            onSelect={canDictorChange ? handleSelect : undefined}
            onFocus={() => handleEditableFocused(phraseIndex)}
            onBlur={() => editedElement.current && editedElement.current.focus()}
            onKeyDown={event => handleEditKeyDown(event, phraseIndex)}
            style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
            //onKeyUp={event => handleSelect(event, phrase, phraseIndex)}
            // content=""
          >
            {text}
          </div>
          {phrase.dictor && showEditDictorButton && (
            <div
              tabIndex={-1}
              style={{
                outline: 'none',
                position: 'fixed',
                top: `${getYFromRange(selectedRange?.range)}px`,
                left: `${getXFromRange(selectedRange?.range)}px`, //phrase.channelNumber !== 0 ? '0' : undefined,
                //right: phrase.channelNumber === 0 ? '50px' : undefined,
                zIndex: 99,
              }}
            >
              <SelectButton
                dictor={phrase.dictor}
                onChangeDictor={d => {
                  if (selectedRange) {
                    handleEditComplete({ target: editedElement.current } as ChangeEvent<HTMLDivElement>, phraseIndex, {
                      selection: selectedRange,
                      dictor: d,
                    });
                  }
                }}
                onClose={() => {
                  editedElement.current && editedElement.current.focus();
                }}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};
