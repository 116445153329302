import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
import ruLocale from 'date-fns/locale/ru';

type LocaleMap = {
  en: Locale;
  es: Locale;
  ru: Locale;
  pt: Locale;
};

const localeMap: LocaleMap = {
  en: enLocale,
  es: esLocale,
  ru: ruLocale,
  pt: ptLocale,
};

interface IFilterFieldDatetime {
  value: string | null;
  name: string;
  handleChange: (date: Date | null) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

const FilterFieldDatetime: FC<IFilterFieldDatetime> = props => {
  const { onKeyDown, handleChange, name, value } = props;
  const [dateTimeValue, setDateTimeValue] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const lang: keyof LocaleMap = (localStorage.getItem('language') as keyof LocaleMap) || 'en';
  const selectedLocale: Locale = localeMap[lang];

  const { t } = useTranslation();

  const minDate = new Date('1900-01-01');
  const maxDate = new Date();

  const onDateTimeChange = (date: Date | null): void => {
    if (!date) {
      setError(null);
      setDateTimeValue(null);
      handleChange(null);

      return;
    }

    if (date.toString() === 'Invalid Date') {
      setError(t('eventLog.invalidDateMessage'));

      return;
    }

    if (date < minDate) {
      setError(t('eventLog.minDateMessage'));

      return;
    }

    if (date > maxDate) {
      handleChange(maxDate);
      setError(null);
      setDateTimeValue(maxDate.toISOString());

      return;
    }

    setError(null);
    setDateTimeValue(date.toISOString());

    if (!isOpen && date < minDate) {
      handleChange(minDate);
      setError(t('eventLog.adjustedEndDateMessage'));
    } else {
      handleChange(date);
    }
  };

  const onClosePicker = () => {
    const changedDate = dateTimeValue ? new Date(dateTimeValue) : null;
    handleChange(changedDate);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) setDateTimeValue(value);
  }, [isOpen, value]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={selectedLocale}>
      <KeyboardDateTimePicker
        InputProps={{ disableUnderline: true, style: { paddingLeft: 5 } }}
        KeyboardButtonProps={{ style: { padding: 12, opacity: 0.5 } }}
        variant="inline"
        hideTabs
        ampm={false}
        name={name}
        value={value}
        onChange={onDateTimeChange}
        helperText={error}
        error={!!error}
        minDateMessage={t('minDateMessage')}
        minDate={minDate}
        maxDate={maxDate}
        format="yyyy-MM-dd HH:mm"
        onKeyDown={onKeyDown}
        onClose={onClosePicker}
        fullWidth
        onOpen={() => setIsOpen(true)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FilterFieldDatetime;
