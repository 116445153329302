import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

import { ERecordStatus, IRecord } from '../records';

import { MainDataPanel } from './MainDataPanel';
import { NoDataPanel } from './NoDataPanel';
import { SummaryTopPanel } from './SummaryTopPanel';
import { TranslationErrorPanel } from './TranslationErrorPanel';
import { SUMMARY_PANEL_WIDTH } from './types';

import { gAPP_STORE } from '@/app/app-store';

export const summaryStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));

interface ICommonSummaryPanel {
  record?: IRecord;
}

export const CommonSummaryPanel = observer(({ record }: ICommonSummaryPanel) => {
  const summaryStore = gAPP_STORE.summaryStore;
  const summaryClasses = summaryStyles();

  const classNameContainer = clsx(summaryClasses.container);

  useEffect(() => {
    const disp1 = reaction(
      () => (record ? record.summaryStatus : undefined),
      (cur, prev) => {
        if (summaryStore.returnFromGrid) {
          return;
        }
        if (record && cur !== prev && cur === ERecordStatus.PROCESSED) {
          summaryStore.loadMainTab(record);
        }
      },
    );

    const disp2 = reaction(
      () => (record ? record.summaryTranslationStatus : undefined),
      async (cur, prev) => {
        if (summaryStore.returnFromGrid) {
          return;
        }
        if (cur !== prev && cur === ERecordStatus.NEW) {
          summaryStore.setTabTranslateEmpty();
        }
        if (cur !== prev && cur === ERecordStatus.PROCESSED) {
          summaryStore.setTabTranslateDefault();
          await summaryStore.loadTranslate();
        }
      },
    );

    const disp3 = reaction(
      () =>
        record !== undefined && gAPP_STORE.getDictorsStore().getDictorsWithSegmentationForRecord(record.correlationId),
      actualDictors => {
        if (actualDictors && summaryStore.summaryHistoryReady) {
          summaryStore.updateDictors();
        }
      },
    );

    return () => {
      disp1();
      disp2();
      disp3();
    };
  }, [record, summaryStore]);

  const hasData = useMemo(
    () =>
      record?.summaryStatus === ERecordStatus.PROCESSED ||
      (record && record.id === summaryStore.recordId && summaryStore.history.length !== 0),
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [record, record?.summaryStatus, summaryStore.history.length, summaryStore.recordId],
  );
  const showErrorTranslateStatus = useMemo(
    () => summaryStore.isTranslate && record?.summaryTranslationStatus === ERecordStatus.FAILED,
    [record?.summaryTranslationStatus, summaryStore.isTranslate],
  );

  useEffect(() => {
    if (record) {
      void summaryStore.loadMainTab(record);
    } else {
      summaryStore.clear();
    }
  }, [record, summaryStore]);

  return (
    <div
      data-autotest={'CommonSummaryPanel'}
      id="blockSummary"
      className={classNameContainer}
      style={{
        backgroundColor: 'white',
        minWidth: SUMMARY_PANEL_WIDTH,
      }}
    >
      <div style={{ display: 'flex', height: '100%' }}>
        <div className={classNameContainer} style={{ overflow: 'hidden' }}>
          <SummaryTopPanel text={summaryStore.summaryTitle()} />
          {hasData ? (
            showErrorTranslateStatus ? (
              <TranslationErrorPanel />
            ) : (
              <MainDataPanel className={classNameContainer} />
            )
          ) : (
            <NoDataPanel />
          )}
        </div>
      </div>
    </div>
  );
});
