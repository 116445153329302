import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditIcon from '@material-ui/icons/Edit';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGrid, ECommonDialogCloseStatus, IToolbarExDescriptorItem, Panel, ToolbarEx } from '@uk';

import { reaction } from 'mobx';

import { AutoTranslateLanguageDialog, IAutoTranslateDialogResult } from './auto-translate-dialog';

import { gAPP_STORE } from '@/app/app-store';

const AutoTramslateGrid = () => {
  const { t } = useTranslation();

  useEffect(() => {
    gAPP_STORE.getAutoTranslateLanguagesStore().loadAll();

    return reaction(
      () =>
        gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.data &&
        gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.data.length,
      () =>
        setEditMode(
          gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.data &&
            gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.data.length > 0,
        ),
    );
  }, []);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [editMode, setEditMode] = useState(
    gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.data &&
      gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.data.length > 0,
  );

  const handleCloseOtherSettingDialog = useCallback((dialogResult: IAutoTranslateDialogResult) => {
    setOpenCreateDialog(false);
    setOpenEditDialog(false);
    if (dialogResult.closeStatus === ECommonDialogCloseStatus.OK) {
      if (dialogResult.oldValue) {
        gAPP_STORE.getAutoTranslateLanguagesStore().update(dialogResult.setting, dialogResult.oldValue);
      } else {
        gAPP_STORE.getAutoTranslateLanguagesStore().add(dialogResult.setting);
      }
    }

    gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.setSelectedRow(undefined);
  }, []);

  const handleCreateSetting = useCallback(() => {
    setOpenCreateDialog(true);
  }, []);

  const handleEditOtherSetting = useCallback(() => {
    const store = gAPP_STORE.getAutoTranslateLanguagesStore().gridStore;
    if (store.data.length > 0) {
      store.setSelectedRow(store.data[0]);
    }
    if (store.selectedRow) {
      setOpenEditDialog(true);
    }
  }, []);

  const handleDeleteOtherSetting = useCallback(() => {
    const store = gAPP_STORE.getAutoTranslateLanguagesStore().gridStore;
    if (store.data.length > 0) {
      store.setSelectedRow(store.data[0]);
    }

    const selected = store.selectedRow;
    if (selected) {
      if (window.confirm(t('autoTranslate.deleteConfirm'))) {
        gAPP_STORE.getAutoTranslateLanguagesStore().delete(selected);
      }
    }
  }, [t]);

  const editButton = useCallback((): IToolbarExDescriptorItem => {
    return !editMode
      ? {
          type: 'button',
          text: 'add',
          icon: <AddIcon />,
          onClick: handleCreateSetting,
          pl: 1,
        }
      : {
          type: 'button',
          text: 'edit',
          disabled: gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.selectedRow ? false : true,
          icon: <EditIcon />,
          onClick: handleEditOtherSetting,
          pl: 1,
        };
  }, [editMode, handleCreateSetting, handleEditOtherSetting]);

  const toolbarDescriptor: IToolbarExDescriptorItem[] = useMemo(
    () => [
      { type: 'text', text: `${t('autoTranslate.title')}`, pr: 1 },
      { type: 'divider', pl: 1 },
      editButton(),
      {
        type: 'button',
        text: 'delete',
        disabled: gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.selectedRow ? false : true,
        icon: <ClearRoundedIcon />,
        onClick: handleDeleteOtherSetting,
        color: 'red',
        pl: 1,
      },
    ],
    //TODO: Костыль, нужно будет фиксить https://react.dev/learn/removing-effect-dependencies#to-change-the-dependencies-change-the-code
    // оставить задизейблиным чтобы отслеживать выбранные поля в таблице
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.selectedRow, editButton, handleDeleteOtherSetting, t],
  );

  return (
    <Panel>
      <ToolbarEx descriptor={toolbarDescriptor} />
      <Box display="flex" flexGrow={1}>
        <DataGrid
          id="AutoTestGrid"
          gridStore={gAPP_STORE.getAutoTranslateLanguagesStore().gridStore}
          cols={gAPP_STORE.getAutoTranslateLanguagesStore().gridCols}
          inscriptions={gAPP_STORE.tableInscriptions}
          noPaging
        />
      </Box>

      {(openCreateDialog || openEditDialog) && (
        <AutoTranslateLanguageDialog
          onClose={handleCloseOtherSettingDialog}
          translateSettings={
            openEditDialog ? gAPP_STORE.getAutoTranslateLanguagesStore().gridStore.selectedRow : undefined
          }
        />
      )}
    </Panel>
  );
};

export default observer(AutoTramslateGrid);
