import { IGroup } from 'components/groups';

interface ICommonUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  platform: string;
}
export interface IBackUser extends ICommonUser {
  hashTag1: boolean;
  hashTag2: boolean;
  hashTag3: boolean;
  hashTag4?: boolean;
  hashTag5?: boolean;
}

export interface IUser extends ICommonUser {
  groups?: IGroup[];
  isAdmin: boolean;
  isSupervisor: boolean;
  isEditor: boolean;
  isServiceUser?: boolean;
  isAD?: boolean;
}

export class CUser implements IUser {
  id = -1;
  email = '';
  firstName = '';
  lastName = '';
  active = true;
  isAdmin = false;
  isSupervisor = false;
  isEditor = false;
  platform = '';
}
